import React from 'react'

export default class ItemOverlay extends React.Component {

    render() {

        return (

            <div className={ 'item-overlay' + ( this.props.className ? ' ' + this.props.className : '' ) }>

                <div>{ this.props.children }</div>

            </div>

        )

    }

}