import React from 'react'
import { Card, Form } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import { Icon, ajx, translate as _, ajxs } from '@morawadigital/skynet-framework'
import { getFieldDefinition } from '../../fieldDefinition'
import Floaties from '../controls/Floaties'
import FieldSet from '../elements/FieldSet'

export default class ItemPage extends React.Component {

    objectName
    pageIcon
    pageTitle
    valueOptions

    constructor( props ) {

        super( props )

        this.state = {

            fieldDefinition:   null,
            hasUnsavedChanges: false,
            item:              this.createItem(),
            saving:            false,
            validated:         false,
            valueOptions:      {},

        }

        this.checkUnsavedChanges = this.checkUnsavedChanges.bind( this )

    }

    componentDidMount() {

        window.addEventListener( 'beforeunload', this.checkUnsavedChanges )

        this.loadFieldDefinition()

        this.load()

    }

    componentWillUnmount() {

        window.removeEventListener( 'beforeunload', this.checkUnsavedChanges )

    }

    checkUnsavedChanges( e ) {

        if ( this.state.hasUnsavedChanges ) {

            e.preventDefault()

            return e.returnValue = _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' )

        }

    }

    createItem() {

        return {}

    }

    delete() {

        // TODO Delete item
        alert( 'TODO Delete item' )

    }

    getItemId() {

        return this.state.item.Id || this.props.match.params.id || 0

    }

    getItemName() {

        return this.state.item.Name || ''

    }

    getSaveData() {

        return {}

    }

    load() {

        const items        = []
        const valueOptions = {}

        this.valueOptions && this.valueOptions.forEach( valueOption => {

            if ( valueOption.fn ) {

                valueOptions[ valueOption.name ] = valueOption.fn()

            } else {

                items.push( valueOption )

            }

        } )

        this.setState( { valueOptions }, () => {

            if ( this.getItemId() !== _( 'neu' ) ) {

                items.push( {

                    data:    { id: this.getItemId() },
                    options: { method: 'GET' },
                    single:  true,
                    success: e => this.setState( { item: this.createItem( e ) } ),
                    url:     'api/' + this.objectName + '/Get',

                } )

            }

            items.length && ajxs( { toggleLoading: true }, items )

        } )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: this.objectName,
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    save( e ) {

        e.preventDefault()

        if ( ! e.currentTarget.checkValidity() ) {

            this.setState( { validated: true } )

            return

        }

        this.setState( { saving: true, validated: true }, () => {

            const data = this.getSaveData()

            const success = e => {

                const itemId = this.getItemId()
                const state  = { hasUnsavedChanges: false }

                if ( ! this.state.item.Id ) {

                    state.item = { ...this.state.item, Id: e.Id }

                }

                this.setState( state, () => itemId === _( 'neu' ) && this.props.history.push( this.slug + e.Id ) )

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                single:   true,
                url:      'api/' + this.objectName + '/AddOrEdit',

                data, success,

            } )

        } )

    }

    render() {

        const formDisabled = this.props.loading || this.state.saving

        return (

            <Form onSubmit={ e => this.save( e ) } noValidate validated={ this.state.validated }>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon={ this.pageIcon } className='subheader-icon' /> { this.pageTitle } { this.state.item && <span className='fw-300'>{ this.getItemName() }</span> }

                    </h1>

                </div>

                <Card>

                    <fieldset disabled={ formDisabled }>

                        <Card.Body>

                            <FieldSet
                                disabled={ formDisabled }
                                fieldDefinition={ this.state.fieldDefinition }
                                onChange={ ( e, f ) => this.setState( { item: { ...this.state.item, [ e ]: f }, hasUnsavedChanges: true } ) }
                                valueOptions={ this.state.valueOptions }
                                values={ this.state.item }
                            />

                        </Card.Body>

                    </fieldset>

                </Card>

                <Floaties buttons={ [

                    { icon: 'save',  label: _( 'Speichern' ), disabled: formDisabled, type: 'submit' },
                    { icon: 'trash', label: _( 'Löschen'   ), disabled: formDisabled, onClick: () => this.delete(), variant: 'danger', outline: true, position: 'end' },

                ] } />

                <Prompt message={ _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' ) } when={ this.state.hasUnsavedChanges } />

            </Form>

        )

    }

}