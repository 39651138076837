import { Datatron } from '@morawadigital/skynet-framework'
import { documentType } from '../objectStores/documentType'
import { fieldDefinition } from '../objectStores/fieldDefinition'
import { getCustomerKey } from './customer'

export const datatron = new Datatron( {

    dbName:       'myFederation_' + getCustomerKey(),
    // debug:        true,
    objectStores: [ documentType, fieldDefinition ],

} )