import React from 'react'
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { translate as _ } from '@morawadigital/skynet-framework'

export default class TabButton extends React.Component {

    render() {

        return (

            <Nav.Item role='button'>

            {

                ! this.props.disabled ?

                    <Nav.Link eventKey={ this.props.eventKey }>{ this.props.label }</Nav.Link>

                :

                    <OverlayTrigger overlay={ <Tooltip>{ _( 'Speichern zum Bearbeiten' ) }</Tooltip> }>

                        <span className='cursor-not-allowed'>

                            <Nav.Link eventKey={ this.props.eventKey } disabled>{ this.props.label }</Nav.Link>

                        </span>

                    </OverlayTrigger>

            }

            </Nav.Item>

        )

    }

}