import { connect } from 'react-redux'
import Club from '../../components/pages/Club'

const mapStateToProps = state => ( {

    context: state.ui.context,
    loading: state.app.loading,

} )

export default connect( mapStateToProps )( Club )