import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_DATE, FIELD_TYPE_TEXTAREA, FIELD_TYPE_TEXTFIELD, FIELD_TYPE_TOGGLE } from '../util/constants'

export const TransferCard = {

    'AdditionalClub':    { __label: _( 'Zusätzlicher Verein' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'clubs'                },
    'alreadyCalculated': { __label: _( 'Already Calculated'  ), __type: FIELD_TYPE_TOGGLE                                             },
    'Club':              { __label: _( 'Verein'              ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'clubs'                },
    'From':              { __label: _( 'Von'                 ), __type: FIELD_TYPE_DATE                                               },
    'LeavingFederation': { __label: _( 'Leaving Federation'  ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'federations'          },
    'MainFederation':    { __label: _( 'Main Federation'     ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'federations'          },
    'Note':              { __label: _( 'Notiz'               ), __type: FIELD_TYPE_TEXTAREA                                           },
    'Number':            { __label: _( 'Nummer'              ), __type: FIELD_TYPE_TEXTFIELD                                          },
    'RequestDate':       { __label: _( 'Request Date'        ), __type: FIELD_TYPE_DATE                                               },
    'RestrictionLeague': { __label: _( 'Restriction League'  ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'leagues'              },
    'RestrictionType':   { __label: _( 'Restriction Type'    ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'restrictionTypes'     },
    'Status':            { __label: _( 'Status'              ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'transferCardStatuses' },
    'Till':              { __label: _( 'Bis'                 ), __type: FIELD_TYPE_DATE                                               },

}