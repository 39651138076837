import React from 'react'
import { Alert, Button, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ConfirmationModal, ajx, translate as _, Icon } from '@morawadigital/skynet-framework'
import PlayerLicenseItem from '../items/PlayerLicense'
import PlayerLicenseRequestItem from '../items/PlayerLicenseRequest'
import PlayerLicenseModal from '../modals/PlayerLicense'
import PlayerLicenseRequestModal from '../modals/PlayerLicenseRequest'
import { createOptions } from '../../util'
import { PLAYER_LICENSE_STATUS_VALID } from '../../util/constants'
import { isClub, isFederation } from '../../util/context'

export default class PlayerLicenses extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            playerLicenseDeleting:      null,
            playerLicenseEditing:       null,
            playerLicenseRequestOpen:   false,
            playerLicenseTypes:         null,
            playerLicenseTypesComplete: null,
            showInactivePlayerLicenses: false,

        }

    }

    componentDidMount() {

        this.load()

    }

    createPlayerLicense( playerLicense ) {

        const createdPlayerLicense = {

            Club:                         null,
            DevelopmentLicense:           false,
            DocumentArrival:              null,
            FromDate:                     null,
            Id:                           0,
            LicenseExtensionSecondLeague: false,
            LicensePrintDate:             null,
            LicenseReturnDate:            null,
            Note:                         '',
            PlayerId:                     0,
            PlayerLicenseType:            null,
            PlayoffClub:                  null,
            Release:                      false,
            Status:                       null,
            ToDate:                       null,

            ...( playerLicense || {} ),

        }

        if ( playerLicense ) {

            createdPlayerLicense.Club              = playerLicense.Club              ? { label: playerLicense.Club.Name,              value: playerLicense.Club.Id              } : null
            createdPlayerLicense.PlayerLicenseType = playerLicense.PlayerLicenseType ? { label: playerLicense.PlayerLicenseType.Name, value: playerLicense.PlayerLicenseType.Id } : null
            createdPlayerLicense.PlayoffClub       = playerLicense.PlayoffClub       ? { label: playerLicense.PlayoffClub.Name,       value: playerLicense.PlayoffClub.Id       } : null

        }

        return createdPlayerLicense

    }

    deletePlayerLicense() {

        ajx( {

            data: { id: this.state.playerLicenseDeleting.Id },
            url:  'api/PlayerLicense/Delete',

            success: () => {

                const playerLicense = this.state.playerLicenseDeleting

                this.setState( { playerLicenseDeleting: null }, () => this.props.onDelete( playerLicense ) )

            },

        } )

    }

    load() {

        const data = {}

        if ( this.props.context ) {

            if ( this.props.context.federation ) { data.fedId  = this.props.context.federation.id }
            if ( this.props.context.club       ) { data.clubId = this.props.context.club.id       }

        }

        ajx( {

            options: { method: 'GET' },
            success: e => this.setState( { playerLicenseTypes: createOptions( e ), playerLicenseTypesComplete: e } ),
            url:     'api/PlayerLicenseType/Get',

            data,

        } )

    }

    openPlayerLicense( e ) {

        this.setState( { playerLicenseEditing: this.createPlayerLicense( e ) } )

    }

    renderMissingDocumentsAlert( e, f ) {

        return (

            this.props[ e ].length > 0 &&

                <Alert bg='info' className='mt-1'>

                    <Icon icon='info-circle' /> { f }

                    <ul className='my-2'>

                        { this.props[ e ].map( ( e, i ) => <li key={ i }>{ e.name }</li> ) }

                    </ul>

                    { _( 'Bitte im Tab "Dateien" hochladen.' ) }

                </Alert>

        )

    }

    render() {

        const activePlayerLicenses   = this.props.playerLicenses.filter( e => e.Status === PLAYER_LICENSE_STATUS_VALID )
        const inactivePlayerLicenses = this.props.playerLicenses.filter( e => e.Status !== PLAYER_LICENSE_STATUS_VALID )

        return (

            <>

                { isClub( this.props.context ) ?

                    this.state.playerLicenseTypes && this.state.playerLicenseTypes.length ?

                        <>

                            <Button onClick={ () => this.setState( { playerLicenseRequestOpen: true } ) } disabled={ this.props.missingDocumentsForPlayerLicenseRequest.length > 0 }>{ _( 'Lizenz beantragen' ) }</Button>

                            { this.renderMissingDocumentsAlert( 'missingDocumentsForPlayerLicenseRequest', _( 'Folgende Dateien müssen für einen Lizenzantrag vorhanden sein:' ) ) }

                        </>

                    :

                        <OverlayTrigger overlay={ <Tooltip>{ this.props.isFemale ? _( 'Für diese Spielerin kann keine Lizenz beantragt werden.' ) : _( 'Für diesen Spieler kann keine Lizenz beantragt werden.' ) }</Tooltip> }>

                            <span><Button disabled>{ _( 'Lizenz beantragen' ) }</Button></span>

                        </OverlayTrigger>

                : isFederation( this.props.context ) &&

                    <>

                        {/* <Button onClick={ () => this.openPlayerLicense( { PlayerId: this.props.playerId } ) } disabled={ this.props.missingDocumentsForPlayerLicense.length > 0 }>{ _( 'Neue Lizenz' ) }</Button>

                        { this.renderMissingDocumentsAlert( 'missingDocumentsForPlayerLicense', _( 'Folgende Dateien müssen für eine Lizenz vorhanden sein:' ) ) } */}

                    </>

                }

                { this.props.playerLicenseRequests && this.props.playerLicenseRequests.length > 0 &&

                    <div className='mt-3'>

                        <h6>{ _( 'Offene Lizenzanträge' ) } ({ this.props.playerLicenseRequests.length })</h6>

                        { this.props.playerLicenseRequests.map( ( e, i ) =>

                            <PlayerLicenseRequestItem key={ i } item={ e } />

                        ) }

                    </div>

                }

                <div className='mt-3'>

                    <h6>{ _( 'Aktive Lizenzen' ) } ({ activePlayerLicenses.length })</h6>

                    { activePlayerLicenses.length ?

                        activePlayerLicenses.map( ( e, i ) =>

                            <PlayerLicenseItem
                                editable={ isFederation( this.props.context ) }
                                item={ e }
                                key={ i }
                                onDelete={ playerLicenseDeleting => this.setState( { playerLicenseDeleting } ) }
                                onEdit={ e => this.openPlayerLicense( e ) }
                            />

                        )

                    :

                        <p className='text-muted'>{ _( 'Keine Lizenzen vorhanden.' ) }</p>

                    }

                </div>

                { isFederation( this.props.context ) &&

                    <div className='mt-3'>

                        <h6>{ _( 'Lizenzhistorie' ) } ({ inactivePlayerLicenses.length })</h6>

                        { inactivePlayerLicenses.length ?

                            <>

                                <Button onClick={ () => this.setState( { showInactivePlayerLicenses: ! this.state.showInactivePlayerLicenses } ) } variant='outline-secondary'>{ this.state.showInactivePlayerLicenses ? _( 'Verbergen' ) : _( 'Anzeigen' ) }</Button>

                                <Collapse in={ this.state.showInactivePlayerLicenses }>

                                    <div className='mt-3'>

                                        { inactivePlayerLicenses.map( ( e, i ) => <PlayerLicenseItem item={ e } key={ i } /> ) }

                                    </div>

                                </Collapse>

                            </>

                        :

                            <p className='text-muted'>{ _( 'Keine Lizenzen vorhanden.' ) }</p>

                        }

                    </div>

                }

                { isClub( this.props.context ) ?

                    <PlayerLicenseRequestModal
                        clubId={ this.props.context.club.id }
                        onAdd={ e => this.props.onAddPlayerLicenseRequest( e ) }
                        onHide={ () => this.setState( { playerLicenseRequestOpen: false } ) }
                        playerId={ this.props.playerId }
                        playerLicenseTypes={ this.state.playerLicenseTypes }
                        show={ this.state.playerLicenseRequestOpen }
                    />

                :

                    <>

                        <PlayerLicenseModal
                            onHide={ () => this.setState( { playerLicenseEditing: null } ) }
                            onUpdate={ e => this.props.onUpdate( e ) }
                            playerLicense={ this.state.playerLicenseEditing }
                            playerLicenseTypesComplete={ this.state.playerLicenseTypesComplete }
                            valueOptions={ { ...( this.props.valueOptions || {} ), playerLicenseTypes: this.state.playerLicenseTypes } }
                        />

                        <ConfirmationModal
                            onConfirm={ () => this.deletePlayerLicense() }
                            onHide={ () => this.setState( { playerLicenseDeleting: null } ) }
                            show={ this.state.playerLicenseDeleting !== null }
                            text={ _( 'Möchten Sie diese Lizenz wirklich löschen?' ) }
                            title={ _( 'Lizenz löschen?' ) }
                        />

                    </>

                }

            </>

        )

    }

}