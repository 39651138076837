import { withRouter } from 'react-router-dom'
import { translate as _ } from '@morawadigital/skynet-framework'
import ItemPage from './ItemPage'
import { getStringValue } from '../../util'

class Nation extends ItemPage {

    constructor( props ) {

        super( props )

        this.objectName = 'Nation'
        this.pageIcon   = 'earth-americas'
        this.pageTitle  = _( 'Nation' )
        this.slug       = _( '/nation/' )

    }

    createItem( nation ) {

        const createdNation = {

            Id:   0,
            Name: '',

            ...( nation || {} ),

        }

        return createdNation

    }

    getSaveData() {

        return {

            id:   this.state.item.Id,
            name: getStringValue( this.state.item.Name ),

        }

    }

}

export default withRouter( Nation )