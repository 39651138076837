import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_DATE, FIELD_TYPE_NUMBER, FIELD_TYPE_TEXTAREA, FIELD_TYPE_TEXTFIELD, FIELD_TYPE_TOGGLE } from '../util/constants'

export const Coach = {

    'CoachNumber':                  { __label: _( 'Coach Number'                    ), __type: FIELD_TYPE_TEXTFIELD },
    'CodeOfHonorDoc':               { __label: _( 'Code Of Honor Doc'               ), __type: FIELD_TYPE_TOGGLE    },
    'CriminalRecordCertificateDoc': { __label: _( 'Criminal Record Certificate Doc' ), __type: FIELD_TYPE_TOGGLE    },
    'DOSBNumber':                   { __label: _( 'DOSB Number'                     ), __type: FIELD_TYPE_TEXTFIELD },
    'FirstAidProofDoc':             { __label: _( 'First Aid Proof Doc'             ), __type: FIELD_TYPE_TOGGLE    },
    'LastPrintDate':                { __label: _( 'Last Print Date'                 ), __type: FIELD_TYPE_DATE      },
    'LastSendDate':                 { __label: _( 'Last Print Date'                 ), __type: FIELD_TYPE_DATE      },
    'LimsId':                       { __label: _( 'Lims Id'                         ), __type: FIELD_TYPE_NUMBER    },
    'Note':                         { __label: _( 'Notiz'                           ), __type: FIELD_TYPE_TEXTAREA  },

}