import { Icon } from '@morawadigital/skynet-framework'
import React from 'react'

export default class HeaderItem extends React.Component {

    render() {

        const className = [ 'header-item' ]

        ! this.props.showItem && className.push( 'hidden-md-down' )
        this.props.showOptions && this.props.options && className.push( 'header-item-options-open' )

        return (

            <>

                <div className='hidden-md-up'>

                    <div className='header-icon' onClick={ () => this.props.onToggleItem() }><Icon icon={ this.props.buttonIcon } /></div>

                </div>

                <div className={ className.join( ' ' ) }>

                    <div className='header-item-container'>{ this.props.item }</div>

                    { this.props.showOptions && this.props.options &&

                        <div className='header-item-options'>

                            { this.props.options }

                        </div>

                    }

                </div>

            </>

        )

    }

}