import { connect } from 'react-redux'
import { setUiValue } from '../../store/actions'
import Clubs from '../../components/pages/Clubs'

const mapStateToProps = state => ( {

    clubTypeFilter:   state.ui.clubTypeFilterClubs   || '',
    federationFilter: state.ui.federationFilterClubs || '',
    loading:          state.app.loading,
    textFilter:       state.ui.textFilterClubs       || '',

} )

const mapDispatchToProps = dispatch => ( {

    onUiValueChange: ( key, value ) => dispatch( setUiValue( key, value ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( Clubs )