import { translate as _, formatDateTime } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_DATE, FIELD_TYPE_TEXTFIELD } from '../util/constants'
import { getFullName, getPersonRequestStatus } from '../util'

const displayClub = e => e.value && e.value.Name

const displayDate = e => e.value && formatDateTime( e.value )

const displayName = e => e.value && getFullName( e.value )

const displayStatus = e => e.value && getPersonRequestStatus( e.value ) && getPersonRequestStatus( e.value ).label

export const PersonRequest = {

    'Birthdate':        { __label: _( 'Geburtstag'    ), __type: FIELD_TYPE_DATE                                       },
    'BirthName':        { __label: _( 'Mädchenname'   ), __type: FIELD_TYPE_TEXTFIELD                                  },
    'Club':             { __label: _( 'Verein'        ), __displayFn: displayClub                                      },
    'FirstName':        { __label: _( 'Vorname'       ), __type: FIELD_TYPE_TEXTFIELD                                  },
    'Gender':           { __label: _( 'Geschlecht'    ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'genders'      },
    'LastName':         { __label: _( 'Nachname'      ), __type: FIELD_TYPE_TEXTFIELD                                  },
    'RequestBy':        { __label: _( 'Antragsteller' ), __displayFn: displayName                                      },
    'RequestDate':      { __label: _( 'Beantragt'     ), __displayFn: displayDate                                      },
    'SolvedBy':         { __label: _( 'Bearbeiter'    ), __displayFn: displayName                                      },
    'SolvedDate':       { __label: _( 'Bearbeitet'    ), __displayFn: displayDate                                      },
    'Status':           { __label: _( 'Status'        ), __displayFn: displayStatus                                    },
    'TargetLeagueType': { __label: _( 'Liga'          ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'leaguesTypes' },

}