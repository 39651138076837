import { withRouter } from 'react-router-dom'
import { translate as _ } from '@morawadigital/skynet-framework'
import { createOptions, getGenders, getNumberValue, getSelectValue, getStringValue } from '../../util'
import ItemPage from './ItemPage'

class AgeGroup extends ItemPage {

    constructor( props ) {

        super( props )

        this.objectName = 'AgeGroup'
        this.pageIcon   = 'people-group'
        this.pageTitle  = _( 'Altersklasse' )
        this.slug       = _( '/altersklasse/' )

        this.valueOptions = [

            { name: 'genders', fn: getGenders },
            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, ageGroups: createOptions( e ) } } ), url: 'api/AgeGroup/Get' },

        ]

    }

    createItem( ageGroup ) {

        const createdAgeGroup = {

            FromAge:        null,
            Gender:         null,
            Id:             0,
            Name:           '',
            ParentAgeGroup: null,
            PassExpression: '',
            Shortname:      '',
            ToAge:          null,

            ...( ageGroup || {} ),

        }

        if ( ageGroup ) {

            createdAgeGroup.Gender         = ageGroup.Gender !== null ? getGenders().find( e => e.value === ageGroup.Gender )                      : null
            createdAgeGroup.ParentAgeGroup = ageGroup.ParentAgeGroup  ? { label: ageGroup.ParentAgeGroup.Name, value: ageGroup.ParentAgeGroup.Id } : null

        }

        return createdAgeGroup

    }

    getSaveData() {

        return {

            fromAge:          getNumberValue( this.state.item.FromAge        ),
            gender:           getSelectValue( this.state.item.Gender         ),
            id:               this.state.item.Id,
            name:             getStringValue( this.state.item.Name           ),
            parentAgeGroupId: getSelectValue( this.state.item.ParentAgeGroup ),
            passExpression:   getStringValue( this.state.item.PassExpression ),
            shortname:        getStringValue( this.state.item.Shortname      ),
            toAge:            getNumberValue( this.state.item.ToAge          ),

        }

    }

}

export default withRouter( AgeGroup )