import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_DATE, FIELD_TYPE_TEXTAREA, FIELD_TYPE_TEXTFIELD } from '../util/constants'

export const CoachTraining = {

    'From':  { __label: _( 'Von'   ), __type: FIELD_TYPE_DATE      },
    'Name':  { __label: _( 'Name'  ), __type: FIELD_TYPE_TEXTFIELD },
    'Note':  { __label: _( 'Notiz' ), __type: FIELD_TYPE_TEXTAREA  },
    'Place': { __label: _( 'Ort'   ), __type: FIELD_TYPE_TEXTFIELD },
    'To':    { __label: _( 'Bis'   ), __type: FIELD_TYPE_DATE      },

}