import React from 'react'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import HeaderItem from '../elements/HeaderItem'
import { Col, Row } from 'react-bootstrap'
import { getFullName } from '../../util'

class Context extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            showItem: false,

        }

    }

    changeContext( context ) {

        this.props.onSetContext( context )

        this.props.history.push( '/' )

    }

    filterContexts( candidate, input ) {

        return candidate.data.label.toLowerCase().includes( input.toLowerCase() )

    }

    renderContextLabel( e ) {

        return (

            <Row className='align-items-center flex-nowrap'>

                <Col xs='auto fs-5 ps-4 pe-1 text-center'>

                    <Icon icon={ e.club ? 'flag' : e.federation ? 'cube' : '' } />

                </Col>

                <Col>

                    <div className='fw-500 line-height-1'>{ e.club ? e.club.name : e.federation ? e.federation.name : '' }</div>

                    <div className='text-muted fw-300 line-height-1'>{ getFullName( e.person ) }</div>

                </Col>

            </Row>

        )

    }

    renderInput() {

        return (

            this.props.contexts && this.props.contexts.length ?

                <Select
                    filterOption={ ( e, f ) => this.filterContexts( e, f ) }
                    getOptionLabel={ e => this.renderContextLabel( e ) }
                    onChange={ e => this.changeContext( e ) }
                    options={ this.props.contexts }
                    placeholder=''
                    value={ this.props.context }
                />

            :

                <div className='text-warning'><Icon icon='exclamation-triangle' /> { _( 'Keine Berechtigung' ) }</div>

        )

    }

    render() {

        return (

            <HeaderItem
                buttonIcon='user'
                item={ this.renderInput() }
                onToggleItem={ () => this.setState( { showItem: ! this.state.showItem } ) }
                showItem={ this.state.showItem }
            />

        )

    }

}

export default withRouter( Context )