import { connect } from 'react-redux'
import Dashboard from '../../components/pages/Dashboard'

const mapStateToProps = state => ( {

    loading: state.app.loading,
    token:   state.auth.token,

} )

export default connect( mapStateToProps )( Dashboard )