import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Col, Form, Row } from 'react-bootstrap'
import { Icon, ajxs, translate as _ } from '@morawadigital/skynet-framework'
import ItemsPage from './ItemsPage'
import Club from '../../containers/items/Club'
import { createOptions, findTextInObject } from '../../util'

export default class Clubs extends ItemsPage {

    constructor( props ) {

        super( props )

        this.itemComponent = Club
        this.pageIcon      = 'flag'
        this.pageTitle     = _( 'Vereine' )

    }

    checkItem( item ) {

        if ( this.props.textFilter && ! findTextInObject( item, this.props.textFilter, [ 'Name', 'Shortname', 'Passname' ] ) ) {

            return false

        }

        if ( this.props.clubTypeFilter && this.props.clubTypeFilter.length && ! this.props.clubTypeFilter.find( e => e.value === item.ClubType.Id ) ) {

            return false

        }

        if ( this.props.federationFilter && this.props.federationFilter.length && ! this.props.federationFilter.find( e => e.value === item.Federation.Id ) ) {

            return false

        }

        return true

    }

    getDataUrl() {

        return 'api/Club/Get'

    }

    loadFilters() {

        ajxs( { toggleLoading: true }, [

            { options: { method: 'GET' }, success: e => this.setState( { filterData: { ...this.state.filterData, clubTypes:   createOptions( e ) } } ), url: 'api/ClubType/Get'   },
            { options: { method: 'GET' }, success: e => this.setState( { filterData: { ...this.state.filterData, federations: createOptions( e ) } } ), url: 'api/Federation/Get' },

        ] )

    }

    renderFilters() {

        return (

            <Form className='sticky-filters'>

                <Row>

                    <Col>

                        <Form.Control
                            onChange={ e => this.props.onUiValueChange( 'textFilterClubs', e.target.value ) }
                            placeholder={ _( 'Vereine filtern...' ) }
                            value={ this.props.textFilter }
                        />

                    </Col>

                    <Col>

                        <Select
                            isClearable={ true }
                            isMulti={ true }
                            noOptionsMessage={ () => _( 'Keine Optionen verfügbar' ) }
                            onChange={ e => this.props.onUiValueChange( 'clubTypeFilterClubs', e ) }
                            options={ this.state.filterData.clubTypes }
                            placeholder={ _( 'Vereinstyp' ) }
                            value={ this.props.clubTypeFilter }
                        />

                    </Col>

                    <Col>

                        <Select
                            isClearable={ true }
                            isMulti={ true }
                            noOptionsMessage={ () => _( 'Keine Optionen verfügbar' ) }
                            onChange={ e => this.props.onUiValueChange( 'federationFilterClubs', e ) }
                            options={ this.state.filterData.federations }
                            placeholder={ _( 'Verband' ) }
                            value={ this.props.federationFilter }
                        />

                    </Col>

                </Row>

            </Form>

        )

    }

    renderSubheaderBlock() {

        return (

            <Link to={ _( '/verein/neu' ) } className='btn btn-primary'><Icon icon='plus' /> { _( 'Neu' ) }</Link>

        )

    }

}