import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { MODULE_ACCOUNTING, MODULE_COMPETITION_MANAGEMENT, MODULE_LICENSING } from '../../util/constants'

export default class ModuleSelector extends React.Component {

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            dropDownOpen: false,

        }

        this.#dom = {

            target: React.createRef(),

        }

    }

    handleModuleClick( module ) {

        this.toggleDropDown()

        this.props.onSetModule( module )

    }

    handleToggleClick( e ) {

        e.preventDefault()

        this.toggleDropDown()

    }

    toggleDropDown() {

        this.setState( { dropDownOpen: ! this.state.dropDownOpen } )

    }

    renderLink( module, icon, label ) {

        return (

            <Col>

                <Link to='/' onClick={ () => this.handleModuleClick( module ) } className={ 'text-center' + ( this.props.module === module ? ' active' : '' ) }>

                    <div className='fs-3'><Icon icon={ icon }></Icon></div>

                    <div><small>{ label }</small></div>

                </Link>

            </Col>

        )

    }

    render() {

        return (

            <div>

                <a href='/' onClick={ e => this.handleToggleClick( e ) } className='header-icon' ref={ this.#dom.target }><Icon icon='globe' /></a>

                <Dropdown onToggle={ () => this.toggleDropDown() } show={ this.state.dropDownOpen }>

                    <Dropdown.Menu align='end' data-bs-popper>

                        <div className='module-selector'>

                            <Row className='my-1'>

                                { this.renderLink( MODULE_LICENSING, 'id-card', _( 'Lizenzierung' ) ) }

                                { this.renderLink( MODULE_COMPETITION_MANAGEMENT, 'trophy',  _( 'Spielbetrieb' ) ) }

                            </Row>

                            <Row className='my-1'>

                                { this.renderLink( MODULE_ACCOUNTING, 'piggy-bank', _( 'Rechnungswesen' ) ) }

                            </Row>

                        </div>

                    </Dropdown.Menu>

                </Dropdown>

            </div>

        )

    }

}