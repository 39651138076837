import React from 'react'
import { Icon, translate as _, ajx, formatDate } from '@morawadigital/skynet-framework'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { getFullName } from '../../util'
import Field from '../controls/Field'
import { FIELD_TYPE_TEXTAREA } from '../../util/constants'
import { isClub } from '../../util/context'

export default class RenewalLicenseRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = this.getDefaultState()

    }

    componentDidUpdate( prevProps ) {

        this.props.show !== prevProps.show && this.setState( {

            ...this.getDefaultState(),

        } )

    }

    getDefaultState() {

        return {

            comment:         '',
            complete:        false,
            error:           false,
            loadingPlayers:  false,
            players:         [],
            saving:          false,
            search:          { lastName: '', firstName: '', passNumber: '' },
            selectedPlayer:  null,
            success:         false,

        }

    }

    getStatusMessage( text, className, icon, spin ) {

        const iconProps = { icon }

        if ( spin ) {

            iconProps.spin = true

        }

        return (

            <div className={ 'text-center ' + className }>

                <div className='fs-1 my-3'>

                    <Icon { ...iconProps } />

                </div>

                <p className='lead'>{ text }</p>

            </div>

        )

    }

    loadPlayers( e ) {

        e.preventDefault()

        if ( ! this.searchFormSubmittable() ) {

            return

        }

        this.setState( { loadingPlayers: true }, () => ajx( {

            complete: () => this.setState( { loadingPlayers: false } ),
            options:  { method: 'GET' },
            success:  players => this.setState( { players } ),
            url:      'api/PlayerLicenseRequest/GetPlayer',

            data:     {

                clubId:    ( isClub( this.props.context ) ? this.props.context.club.id : '' ),
                firstName: this.state.search.firstName,
                lastName:  this.state.search.lastName,
                passNr:    this.state.search.passNumber,

            },

        } ) )

    }

    save() {

        this.setState( { saving: true }, () => ajx( {

            complete: () => this.setState( { saving: false, complete: true } ),
            error:    () => this.setState( { error: true } ),
            single:   true,
            success:  e => this.setState( { success: true }, () => this.props.onUpdate( e ) ),
            url:      'api/PlayerLicenseRequest/CreateRenewalLicense',

            data: {

                clubId:   ( isClub( this.props.context ) ? this.props.context.club.id : '' ),
                note:     this.state.comment,
                playerId: this.state.selectedPlayer.Id,

            }

        } ) )

    }

    searchFormSubmittable() {

        return ( this.state.search.lastName || this.state.search.firstName || this.state.search.passNumber )

    }

    renderPlayer( player, i ) {

        return (

            <Card
                className='mb-1 selectable'
                key={ i }
                onClick={ () => this.setState( { selectedPlayer: player } ) }
            >

                <Card.Body className='p-2'>

                    <Row className='gx-0'>

                        <Col xs='3' lg='3'>{ player.PassNr }</Col>

                        <Col>{ getFullName( player.Person, true ) }</Col>

                        <Col xs='auto'>{ player.Person && player.Person.Birthdate ? formatDate( player.Person.Birthdate ) : '' }</Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

    renderRequestForm() {

        return (

            <Field
                field={ { object: 'renewal-license-request', property: 'comment', __label: _( 'Kommentar' ), __type: FIELD_TYPE_TEXTAREA } }
                onChange={ ( _, comment ) => this.setState( { comment } ) }
                value={ this.state.comment }
            />

        )

    }

    renderSearchForm() {

        return (

            <>

                <Form onSubmit={ e => this.loadPlayers( e ) } className='mb-3'>

                    <fieldset disabled={ this.state.loadingPlayers }>

                        <Row className='g-1'>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Nachname'   ) } value={ this.state.search.lastName   } onChange={ e => this.setState( { search: { ...this.state.search, lastName:   e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Vorname'    ) } value={ this.state.search.firstName  } onChange={ e => this.setState( { search: { ...this.state.search, firstName:  e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Passnummer' ) } value={ this.state.search.passNumber } onChange={ e => this.setState( { search: { ...this.state.search, passNumber: e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3' className='d-grid'><Button type='submit' disabled={ ! this.searchFormSubmittable() }>{ _( 'Suchen' ) }</Button></Col>

                        </Row>

                    </fieldset>

                </Form>

                { this.state.players.map( ( e, i ) => this.renderPlayer( e, i ) ) }

            </>

        )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ this.props.onHide } backdrop='static' size='lg'>

                <Modal.Header closeButton>

                    <Modal.Title>{ _( 'Passverlängerung' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    { this.state.selectedPlayer ?

                        ( this.state.saving ?

                            this.getStatusMessage( _( 'Antrag wird gesendet...' ), '', 'spinner', true )

                        : this.state.success ?

                            this.getStatusMessage( _( 'Antrag wurde gesendet.' ), 'text-success', 'check' )

                        : this.state.error ?

                            this.getStatusMessage( _( 'Antrag konnte nicht gesendet werden.' ), 'text-danger', 'times' )

                        :

                            this.renderRequestForm()

                        )

                    :

                        this.renderSearchForm()

                    }

                </Modal.Body>

                <Modal.Footer>

                    { ! this.state.saving && ! this.state.complete && <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button> }

                    { this.state.selectedPlayer && ! this.state.saving && ! this.state.complete && <Button onClick={ () => this.save() }>{ _( 'Antrag senden' ) }</Button> }

                    { this.state.selectedPlayer && this.state.complete && <Button onClick={ this.props.onHide } disabled={ this.state.saving }>{ _( 'Schließen' ) }</Button> }

                </Modal.Footer>

            </Modal>

        )

    }

}