import React from 'react'
import { translate as _ } from '@morawadigital/skynet-framework'
import { Button, Form, Modal } from 'react-bootstrap'
import Field from '../controls/Field'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_TEXTAREA } from '../../util/constants'

export default class ForwardRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            comment:    '',
            federation: null,
            forwarding: false,
            validated:  false,

        }

    }

    componentDidUpdate( prevProps ) {

        if ( prevProps.show !== this.props.show ) {

            this.setState( {

                comment:    '',
                federation: null,
                forwarding: false,
                validated:  false,

            } )

        }

    }

    foward( e ) {

        e.preventDefault()

        if ( ! e.currentTarget.checkValidity() ) {

            this.setState( { validated: true } )

            return

        }

        this.setState( { forwarding: true }, () =>  this.props.onFoward( { comment: this.state.comment, federationId: this.state.federation.value } ) )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ this.props.onHide } backdrop='static'>

                <Form onSubmit={ e => this.foward( e ) } noValidate validated={ this.state.validated }>

                    <fieldset disabled={ this.state.forwarding }>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Antrag weiterleiten' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            <Field
                                disabled=    { this.state.forwarding }
                                field=       { { object: 'forward-request', property: 'federation', isRequired: true, __label: _( 'Verband' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'federations' } }
                                onChange=    { ( _, federation ) => this.setState( { federation } ) }
                                value=       { this.state.federation }
                                valueOptions={ { federations: this.props.federations } }
                            />

                            <Field
                                field=   { { object: 'forward-request', property: 'comment', isRequired: true, rows: 5, __label: _( 'Kommentar' ), __type: FIELD_TYPE_TEXTAREA } }
                                onChange={ ( _, comment ) => this.setState( { comment } ) }
                                value=   { this.state.comment }
                            />

                        </Modal.Body>

                        <Modal.Footer>

                            <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                            <Button type='submit'>{ _( 'Weiterleiten' ) }</Button>

                        </Modal.Footer>

                    </fieldset>

                </Form>

            </Modal>

        )

    }

}