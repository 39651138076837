import { connect } from 'react-redux'
import Search from '../../components/controls/Search'
import { setUiValue } from '../../store/actions'

const mapStateToProps = state => ( {

    searchHistory: state.ui.searchHistory || [],

} )

const mapDispatchToProps = dispatch => ( {

    onSetSearchHistory: searchHistory => dispatch( setUiValue( 'searchHistory', searchHistory ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( Search )