import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_DATE, FIELD_TYPE_NUMBER, FIELD_TYPE_TEXTAREA, FIELD_TYPE_TOGGLE } from '../util/constants'

export const CoachLicense = {

    'FromDate':                     { __label: _( 'Von'                            ), __type: FIELD_TYPE_DATE     },
    'InterdisciplinaryTheoryGrade': { __label: _( 'Interdisciplinary Theory Grade' ), __type: FIELD_TYPE_NUMBER   },
    'IsGueststLicense':             { __label: _( 'Is Guestst License'             ), __type: FIELD_TYPE_TOGGLE   },
    'NextTrainingMainFed':          { __label: _( 'Next Training Main Fed'         ), __type: FIELD_TYPE_DATE     },
    'NextTrainingSubFed':           { __label: _( 'Next Training Sub Fed'          ), __type: FIELD_TYPE_DATE     },
    'Note':                         { __label: _( 'Notiz'                          ), __type: FIELD_TYPE_TEXTAREA },
    'PracticeGrade':                { __label: _( 'Practice Grade'                 ), __type: FIELD_TYPE_NUMBER   },
    'TheoryGrade':                  { __label: _( 'Theory Grade'                   ), __type: FIELD_TYPE_NUMBER   },
    'ToDate':                       { __label: _( 'Bis'                            ), __type: FIELD_TYPE_DATE     },

}