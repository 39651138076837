import { ObjectStore } from '@morawadigital/skynet-framework'

export const documentType = new ObjectStore( {

    name:   'DocumentType',
    version: 2,
    indexes: [

        { name: 'id',           convertion: { key: 'Id'           } },
        { name: 'label',        convertion: { key: 'Label'        } },
        { name: 'name',         convertion: { key: 'Name'         } },
        { name: 'object',       convertion: { key: 'Object'       } },
        { name: 'uploadConfig', convertion: { key: 'UploadConfig' } },
        { name: 'otherConfig',  convertion: { key: 'OtherConfig'  } },

    ],

} )