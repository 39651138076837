import React from 'react'
import { withRouter } from 'react-router-dom'
import { Icon, translate as _, ajx, formatDateTime } from '@morawadigital/skynet-framework'
import { Alert, Col, Dropdown, OverlayTrigger, Placeholder, SplitButton, Tooltip } from 'react-bootstrap'
import Item from './Item'
import { getFullName, getSpecialAuthorityRequestStatus, getStatusLabel } from '../../util'
import ItemOverlay from '../elements/ItemOverlay'
import { PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED, PLAYER_LICENSE_REQUEST_STATUS_PENDING, PLAYER_LICENSE_REQUEST_STATUS_REJECTED } from '../../util/constants'

class SpecialAuthorityRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            overlayText: null,

        }

    }

    respond( status, overlayText, overlayTextOnSuccess, overlayIconOnSuccess ) {

        this.setState( { overlayText: <><Icon icon='spinner' spin /> { overlayText }</> }, () =>

            ajx( {

                complete: () => setTimeout( () => this.setState( { overlayText: null } ), 2000 ),
                data:     { id: this.props.item.Id, status },
                single:   true,
                success:  e => this.setState( { overlayText: <><Icon icon={ overlayIconOnSuccess } /> { overlayTextOnSuccess }</> }, () => setTimeout( () => this.props.onUpdate( e ), 2000 ) ),
                url:      'api/SpecialAuthorityRequest/Respond',

            } )

        )

    }

    renderFooter( item ) {

        return (

            <>

                { item && ( item.Note || item.RequestBy || item.RequestDate ) &&

                    <Alert variant='info' className='mt-2 mb-0 p-1'>

                        { item.RequestDate && <span className='me-1'>{ formatDateTime( item.RequestDate ) }</span> }

                        { item.RequestBy && <>{ _( 'von' ) } <span className='fw-700'>{ getFullName( item.RequestBy ) }</span></> }

                        { item.RequestBy && item.Note && ': ' }

                        { item.Note && <span className='fst-italic'>{ item.Note }</span> }

                    </Alert>

                }

                { this.state.overlayText && <ItemOverlay>{ this.state.overlayText }</ItemOverlay> }

            </>

        )

    }

    render() {

        const item       = this.props.item
        const personName = item && item.personName
        const status     = item && getSpecialAuthorityRequestStatus( item.Status )

        return (

            <Item
                colProps={ { xs: personName ? 8 : 12, md: 3 }                                                   }
                footer=  { this.renderFooter( item )                                                            }
                index=   { this.props.index                                                                     }
                item=    { item                                                                                 }
                name=    { personName                                                                           }
                url=     { item && item.Player && item.Player.Person && _( '/person/' ) + item.Player.Person.Id }
            >

                { personName &&

                    <Col xs='4' md='3' lg='2' className='text-end text-md-start'>

                        { item ?

                            item.PlayerLicenseType && item.PlayerLicenseType.Name

                        :

                            <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                        }

                    </Col>

                }

                <Col xs='6' md={ personName ? 3 : 6 } lg={ personName ? 2 : 4}>

                    { item ?

                        item.Club && <OverlayTrigger overlay={ <Tooltip>{ item.Club.Name }</Tooltip> }><span>{ item.Club.Shortname }</span></OverlayTrigger>

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='6' md='3' lg='2' className='text-end'>

                    { item ?

                        item.RequestDate && formatDateTime( item.RequestDate)

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='12' lg='3' className='my-2 text-center text-lg-end'>

                    { item ? (

                        status ? (

                            status.value === PLAYER_LICENSE_REQUEST_STATUS_PENDING ?

                                ( this.props.respondable ?

                                    <SplitButton
                                        align='end'
                                        onClick={ () => this.respond( PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED, _( 'Wird akzeptiert...' ), _( 'Akzeptiert' ), 'check' ) }
                                        title={ _( 'Akzeptieren' ) }
                                    >

                                        <Dropdown.Item as='button' onClick={ () => this.respond( PLAYER_LICENSE_REQUEST_STATUS_REJECTED, _( 'Wird abgelehnt...' ), _( 'Abgelehnt' ), 'times' ) }>{ _( 'Ablehnen' ) }</Dropdown.Item>

                                    </SplitButton>

                                :

                                    getStatusLabel( status.label, 'clock', 'info' )

                                )

                            : status.value === PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED ?

                                getStatusLabel( status.label, 'check', 'success', item.SolvedBy, item.SolvedDate )

                            : status.value === PLAYER_LICENSE_REQUEST_STATUS_REJECTED ?

                                getStatusLabel( status.label, 'times', 'danger', item.SolvedBy, item.SolvedDate )

                            :

                                ''

                        ) : ''

                    ) :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

            </Item>

        )

    }

}

export default withRouter( SpecialAuthorityRequest )