import { connect } from 'react-redux'
import { setUiValue } from '../../store/actions'
import Club from '../../components/items/Club'

const mapDispatchToProps = dispatch => ( {

    onUiValueChange: ( key, value ) => dispatch( setUiValue( key, value ) ),

} )

export default connect( null, mapDispatchToProps )( Club )