import React from 'react'
import { withRouter } from 'react-router-dom'
import { translate as _ } from '@morawadigital/skynet-framework'
import { Col, Dropdown, Placeholder } from 'react-bootstrap'
import { createOptions } from '../../util'
import Item from './Item'

class Club extends React.Component {

    openPlayers() {

        this.props.onUiValueChange( 'clubFilterPersons', createOptions( [ this.props.item ] ) )

        setTimeout( () => this.props.history.push( _( '/personen/' ) ), 200 )

    }

    renderOptions( url ) {

        return (

            <>

                <Dropdown.Item as='button' onClick={ () => this.props.history.push( url ) }>{ _( 'Bearbeiten' ) }</Dropdown.Item>

                <Dropdown.Item as='button' onClick={ () => this.openPlayers() }>{ _( 'Personen verwalten' ) }</Dropdown.Item>

            </>

        )

    }

    render() {

        const item = this.props.item
        const url  = item && _( '/verein/' ) + item.Id

        return (

            <Item
                colProps={ { xs: 12, md: 7 }         }
                index=   { this.props.index          }
                item=    { item                      }
                name=    { item && item.Name         }
                options= { this.renderOptions( url ) }
                url=     { url                       }
            >

                <Col xs='8' md='3'>

                    { item ?

                        item.Shortname

                    :

                        <Placeholder animation='wave'><Placeholder xs='6' /></Placeholder>

                    }

                </Col>

                <Col xs='4' md='2'>

                    { item ?

                        item.ClubNr

                    :

                        <Placeholder animation='wave'><Placeholder xs='6' /></Placeholder>

                    }

                </Col>

            </Item>

        )

    }

}

export default withRouter( Club )