const fileExtensionRegEx = /(?:\.([^.]+))?$/

const fileTypes = {

    'doc':  { icon: 'file-word'  },
    'docx': { icon: 'file-word'  },
    'pdf':  { icon: 'file-pdf'   },
    'xls':  { icon: 'file-excel' },
    'xlsx': { icon: 'file-excel' },

}

const imageFileExtensions = [ 'jpg', 'jpeg', 'png', 'gif' ]

export const getFileExtension = fileName => {

    const fileExtension = fileExtensionRegEx.exec( fileName )[ 1 ]

    return typeof fileExtension === 'string' ? fileExtension.toLowerCase() : ''

}

export const getFileIcon = fileExtension => fileExtension in fileTypes ? fileTypes[ fileExtension ].icon : 'file'

export const getImageFileExtensions = () => imageFileExtensions