import { connect } from 'react-redux'
import ModuleSelector from '../../components/controls/ModuleSelector'
import { setUiValue } from '../../store/actions'

const mapStateToProps = state => ( {

    module: state.ui.module,

} )

const mapDispatchToProps = dispatch => ( {

    onSetModule: module => dispatch( setUiValue( 'module', module ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( ModuleSelector )