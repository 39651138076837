import React from 'react'
import { Icon, ajx, mdr, parseJSON, translate as _, getDataUrl } from '@morawadigital/skynet-framework'
import { Card, Col, Row } from 'react-bootstrap'
import Report from '../elements/Report'

export default class Dashboard extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            reports: null,

        }

    }

    componentDidMount() {

        mdr.load( {

            baseDataUrl: getDataUrl( '' ),
            token:       this.props.token,

        } )

        this.load()

    }

    load() {

        ajx( {

            options:       { method: 'GET' },
            success:       reports => this.setState( { reports: reports.map( e => ( { ...e, Options: parseJSON( e.Options, {} ) } ) ) } ),
            toggleLoading: true,
            url:           'api/Report/Get',

        } )

    }

    renderReport( report ) {

        return (

            <Card>

                <Card.Header>

                    { report.Name }

                </Card.Header>

                <Card.Body>

                    <Report report={ report } />

                </Card.Body>

            </Card>

        )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='dashboard' className='subheader-icon' /> { _( 'Dashboard' ) }

                    </h1>

                </div>

                { this.state.reports &&

                    <Row xs='1' lg='2' xxl='3' className='g-4'>

                        { this.state.reports.map( ( e, i ) => <Col key={ i }>{ this.renderReport( e ) }</Col> ) }

                    </Row>

                }

            </>

        )

    }

}