import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Icon } from '@morawadigital/skynet-framework'

export default class ItemOptions extends React.Component {

    render() {

        return (

            <Dropdown>

                <Dropdown.Toggle className='dropdown-toggle-ellipsis'>

                    <Icon icon='ellipsis-vertical' />

                </Dropdown.Toggle>

                <Dropdown.Menu>

                    { this.props.children }

                </Dropdown.Menu>

            </Dropdown>

        )

    }

}