import { connect } from 'react-redux'
import { setUiValue } from '../../store/actions'
import Requests from '../../components/pages/Requests'
import { PERSON_REQUEST_STATUS_PENDING, REQUEST_TYPE_PERSON } from '../../util/constants'

const mapStateToProps = state => ( {

    context:       state.ui.context,
    loading:       state.app.loading,
    sortDirection: state.ui.sortDirectionRequests || 'desc',
    statusFilter:  state.ui.statusFilterRequests  || PERSON_REQUEST_STATUS_PENDING,
    textFilter:    state.ui.textFilterRequests    || '',
    typeFilter:    state.ui.typeFilterRequests    || REQUEST_TYPE_PERSON,

} )

const mapDispatchToProps = dispatch => ( {

    onUiValueChange: ( key, value ) => dispatch( setUiValue( key, value ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( Requests )