import { getFullName } from '.'

export const convertContexts = persons => {

    const contexts = []

    persons.forEach( person => {

        person.Clubs       && person.Clubs.forEach(       club       => contexts.push( { value: 'p-' + person.Id + '_c-' + club.Id,       label: club.Name       + ' (' + getFullName( person ) + ')', club:       { id: club.Id,       name: club.Name,       shortName: club.Shortname,      federationId: club.FederationId }, person: { id: person.Id, firstName: person.FirstName, lastName: person.LastName } } ) )
        person.Federations && person.Federations.forEach( federation => contexts.push( { value: 'p-' + person.Id + '_f-' + federation.Id, label: federation.Name + ' (' + getFullName( person ) + ')', federation: { id: federation.Id, name: federation.Name, shortName: federation.Shortname                                 }, person: { id: person.Id, firstName: person.FirstName, lastName: person.LastName } } ) )

    } )

    return contexts

}

export const isClub = context => context && context.club

export const isFederation = context => context && context.federation