import React from 'react'
import { Button } from 'react-bootstrap'
import { ConfirmationModal, ajx, translate as _ } from '@morawadigital/skynet-framework'
import TransferCardItem from '../items/TransferCard'
import TransferCardModal from '../modals/TransferCard'
import { isFederation } from '../../util/context'

export default class TransferCards extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            transferCardDeleting: null,
            transferCardEditing:  null,

        }

    }

    createTransferCard( transferCard ) {

        const createdTransferCard = {

            AdditionalClub:    null,
            alreadyCalculated: false,
            Club:              null,
            From:              null,
            Id:                0,
            LeavingFederation: null,
            MainFederation:    null,
            Note:              '',
            Number:            '',
            PlayerId:          0,
            RequestDate:       null,
            RestrictionLeague: null,
            RestrictionType:   null,
            Status:            null,
            Till:              null,

            ...( transferCard || {} ),

        }

        if ( transferCard ) {

            createdTransferCard.AdditionalClub    = transferCard.AdditionalClub    ? { label: transferCard.AdditionalClub.Name,    value: transferCard.AdditionalClub.Id    } : null
            createdTransferCard.Club              = transferCard.Club              ? { label: transferCard.Club.Name,              value: transferCard.Club.Id              } : null
            createdTransferCard.LeavingFederation = transferCard.LeavingFederation ? { label: transferCard.LeavingFederation.Name, value: transferCard.LeavingFederation.Id } : null
            createdTransferCard.MainFederation    = transferCard.MainFederation    ? { label: transferCard.MainFederation.Name,    value: transferCard.MainFederation.Id    } : null
            createdTransferCard.RestrictionLeague = transferCard.RestrictionLeague ? { label: transferCard.RestrictionLeague.Name, value: transferCard.RestrictionLeague.Id } : null
            createdTransferCard.RestrictionType   = transferCard.RestrictionType   ? { label: transferCard.RestrictionType.Title,  value: transferCard.RestrictionType.Id   } : null
            createdTransferCard.Status            = transferCard.Status            ? { label: transferCard.Status.Name,            value: transferCard.Status.Id            } : null

        }

        return createdTransferCard

    }

    deleteTransferCard() {

        ajx( {

            data: { id: this.state.transferCardDeleting.Id },
            url:  'api/TransferCard/Delete',

            success: () => {

                const transferCard = this.state.transferCardDeleting

                this.setState( { transferCardDeleting: null }, () => this.props.onDelete( transferCard ) )

            },

        } )

    }

    openTransferCard( e ) {

        this.setState( { transferCardEditing: this.createTransferCard( e ) } )

    }

    render() {

        return (

            <>

                { isFederation( this.props.context ) && <Button onClick={ () => this.openTransferCard( { PlayerId: this.props.playerId } ) }>{ _( 'Neue Transferkarte' ) }</Button> }

                <div className='mt-3'>

                    { this.props.transferCards.map( ( transferCard, index ) =>

                        <TransferCardItem
                            item={ transferCard }
                            key={ index }
                            onDelete={ transferCardDeleting => this.setState( { transferCardDeleting } ) }
                            onEdit={ e => this.openTransferCard( e ) }
                        />

                    ) }

                </div>

                <TransferCardModal
                    onHide={ () => this.setState( { transferCardEditing: null } ) }
                    onUpdate={ e => this.props.onUpdate( e ) }
                    transferCard={ this.state.transferCardEditing }
                    valueOptions={ this.props.valueOptions }
                />

                <ConfirmationModal
                    onConfirm={ () => this.deleteTransferCard() }
                    onHide={ () => this.setState( { transferCardDeleting: null } ) }
                    show={ this.state.transferCardDeleting !== null }
                    text={ _( 'Möchten Sie diese Transferkarte wirklich löschen?' ) }
                    title={ _( 'Transferkarte löschen?' ) }
                />

            </>

        )

    }

}