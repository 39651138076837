import { Link } from 'react-router-dom'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import ItemsPage from './ItemsPage'
import AgeGroup from '../items/AgeGroup'

export default class AgeGroups extends ItemsPage {

    constructor( props ) {

        super( props )

        this.itemComponent = AgeGroup
        this.pageIcon      = 'people-group'
        this.pageTitle     = _( 'Altersklassen' )

    }

    getDataUrl() {

        return 'api/AgeGroup/Get'

    }

    renderSubheaderBlock() {

        return (

            <Link to={ _( '/altersklasse/neu' ) } className='btn btn-primary'><Icon icon='plus' /> { _( 'Neu' ) }</Link>

        )

    }

}