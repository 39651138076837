import { Icon, translate as _, ajx, deepCopy, parseJSON } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Form, FormCheck, Modal } from 'react-bootstrap'
import FieldSet from '../elements/FieldSet'
import Documents from '../elements/Documents'
import { createOptions, deleteItem, getBooleanValue, getDateValue, getGenders, getSelectValue, getStringValue, olderThan, updateItem, youngerThan } from '../../util'
import { getFieldDefinition } from '../../fieldDefinition'
import { INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED, INITIAL_TRANSFER_CARD_REQUEST_STATUS_DRAFT, INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING, INITIAL_TRANSFER_CARD_REQUEST_STATUS_REJECTED } from '../../util/constants'
import { datatron } from '../../util/datatron'
import { documentType } from '../../objectStores/documentType'
import { isClub } from '../../util/context'

export default class InitialTransferCardRequest extends React.Component {

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            documentTypes:              null,
            error:                      false,
            fieldDefinition:            null,
            initialTransferCardRequest: null,
            isNew:                      false,
            keepDocuments:              true,
            responseStatus:             null,
            saving:                     false,
            show:                       false,
            step:                       1,
            success:                    false,
            validated:                  false,
            valueOptions:               { genders: getGenders() },

        }

        this.#dom = {

            form: React.createRef(),

        }

    }

    componentDidMount() {

        this.load()

        this.loadFieldDefinition()

        this.loadDocumentTypes()

    }

    componentDidUpdate() {

        if ( this.props.initialTransferCardRequest && ! this.state.initialTransferCardRequest ) {

            this.setState( {

                error:                      false,
                initialTransferCardRequest: this.convertInitialTransferCardRequest( this.props.initialTransferCardRequest ),
                isNew:                      this.props.initialTransferCardRequest.isNew,
                keepDocuments:              true,
                saving:                     false,
                step:                       1,
                success:                    false,
                validated:                  false,

            } )

        } else if ( ! this.props.initialTransferCardRequest && this.state.initialTransferCardRequest ) {

            this.setState( { initialTransferCardRequest: null } )

        }

    }

    canEdit() {

        if ( this.state.isNew ) {

            return true

        }

        if ( this.state.initialTransferCardRequest.Status === INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING && this.props.respondable ) {

            return true

        }

        return false

    }

    convertInitialTransferCardRequest( initialTransferCardRequest ) {

        initialTransferCardRequest = deepCopy( initialTransferCardRequest )

        initialTransferCardRequest.Gender            = initialTransferCardRequest.Gender            !== null                                    ? getGenders().find(                    e => e.value === initialTransferCardRequest.Gender               ) : null
        initialTransferCardRequest.LeavingFederation = initialTransferCardRequest.LeavingFederation !== null && this.state.valueOptions.nations ? this.state.valueOptions.nations.find( e => e.value === initialTransferCardRequest.LeavingFederation.Id ) : null
        initialTransferCardRequest.MainFederation    = initialTransferCardRequest.MainFederation    !== null && this.state.valueOptions.nations ? this.state.valueOptions.nations.find( e => e.value === initialTransferCardRequest.MainFederation.Id    ) : null

        return initialTransferCardRequest

    }

    getStatusMessage( text, className, icon, spin ) {

        const iconProps = { icon }

        if ( spin ) {

            iconProps.spin = true

        }

        return (

            <div className={ 'text-center ' + className }>

                <div className='fs-1 my-3'>

                    <Icon { ...iconProps } />

                </div>

                <p className='lead'>{ text }</p>

            </div>

        )

    }

    handleBlur( e ) {

        if ( e === 'LastName' && ! this.state.initialTransferCardRequest.BirthName ) {

            this.setState( { initialTransferCardRequest: { ...this.state.initialTransferCardRequest, BirthName: this.state.initialTransferCardRequest.LastName } } )

        }

    }

    load() {

        ajx( {

            options: { method: 'GET' },
            success: e => this.setState( { valueOptions: { ...this.state.valueOptions, nations: createOptions( e ) } } ),
            url:     'api/Nation/Get'

        } )

    }

    loadDocumentTypes() {

        datatron.get( {

            cacheFirst:     true,
            objectStore:    documentType,
            requestOptions: { options: { method: 'GET' }, url: 'api/DocumentType/Get' },
            success:        documentTypes => this.setState( { documentTypes: documentTypes.data.filter( e => e.object === 'InitialTransferCardRequest' ) } ),

        } )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'InitialTransfercardRequest',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    respond( status ) {

        this.setState( { error: false, responseStatus: status, saving: true, success: false }, () => {

            const data = {

                birthDate:     this.state.initialTransferCardRequest.Birthdate,
                birthName:     getStringValue( this.state.initialTransferCardRequest.BirthName    ),
                id:            this.state.initialTransferCardRequest.Id,
                firstName:     getStringValue( this.state.initialTransferCardRequest.FirstName    ),
                gender:        getSelectValue( this.state.initialTransferCardRequest.Gender       ),
                keepDocuments: getBooleanValue( this.state.keepDocuments                          ),
                lastName:      getStringValue( this.state.initialTransferCardRequest.LastName     ),
                number:        getStringValue( this.state.initialTransferCardRequest.Number       ),
                responseNote:  getStringValue( this.state.initialTransferCardRequest.ResponseNote ),

                status,

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                error:    () => this.setState( { error: true } ),
                single:   true,
                success:  initialTransferCardRequest => this.setState( { initialTransferCardRequest, success: true }, () => this.props.onUpdate( initialTransferCardRequest ) ),
                url:      'api/InitialTransfercardRequest/Respond',

                data,

            } )

        } )

    }

    save( status, step ) {

        if ( this.#dom.form.current && ! this.#dom.form.current.checkValidity() ) {

            this.setState( { validated: true } )

            return

        }

        this.setState( { error: false, saving: true, success: false, validated: true }, () => {

            const data = {

                additionalClubId: null,
                birthDate:        getDateValue( this.state.initialTransferCardRequest.Birthdate           ),
                birthName:        getStringValue( this.state.initialTransferCardRequest.BirthName         ),
                clubId:           isClub( this.props.context ) && this.props.context.club.id,
                firstName:        getStringValue( this.state.initialTransferCardRequest.FirstName         ),
                gender:           getSelectValue( this.state.initialTransferCardRequest.Gender            ),
                lastName:         getStringValue( this.state.initialTransferCardRequest.LastName          ),
                leavingNationId:  getSelectValue( this.state.initialTransferCardRequest.LeavingFederation ),
                mainNationId:     getSelectValue( this.state.initialTransferCardRequest.MainFederation    ),
                note:             getStringValue( this.state.initialTransferCardRequest.Note              ),

                status,

            }

            if ( this.state.initialTransferCardRequest.Id ) {

                data.id = this.state.initialTransferCardRequest.Id

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                error:    () => this.setState( { error: true } ),
                single:   true,
                url:      'api/InitialTransfercardRequest/Create',

                success: initialTransferCardRequest => {

                    initialTransferCardRequest = this.convertInitialTransferCardRequest( initialTransferCardRequest )

                    this.setState( { initialTransferCardRequest, step, success: true }, () => step === 3 && this.props.onUpdate( initialTransferCardRequest ) )

                },

                data,

            } )

        } )

    }

    send() {

        this.setState( { step: 3 }, () => this.save( INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING, 3 ) )

    }

    renderBody() {

        return (

            this.state.saving ?

                this.getStatusMessage( this.state.responseStatus === INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED ? _( 'Antrag wird akzeptiert...' ) : _( 'Antrag wird abgelehnt...' ), '', 'spinner', true )

            : this.state.success ?

                this.getStatusMessage( this.state.responseStatus === INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED ? _( 'Antrag wurde akzeptiert.' ) : _( 'Antrag wurde abgelehnt.' ), 'text-success', 'check' )

            : this.state.error ?

                this.getStatusMessage( this.state.responseStatus === INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED ? _( 'Antrag konnte nicht akzeptiert werden.' ) : _( 'Antrag konnte nicht abgelehnt werden.' ), 'text-danger', 'times' )

            :

                <>

                    { this.renderFields() }

                    <h6 className='my-3 pt-3 border-top text-muted'>{ _( 'Dokumente' ) }</h6>

                    { this.renderDocuments() }

                    { this.state.initialTransferCardRequest.Status === INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING && this.props.respondable &&

                        <FormCheck
                            checked={ this.state.keepDocuments }
                            disabled={ this.state.saving }
                            id='keepDocuments'
                            label={ _( 'Dokumente übernehmen' ) }
                            onChange={ e => this.setState( { keepDocuments: e.target.checked } ) }
                        />

                    }

                </>

        )

    }

    renderButtons() {

        return (

            <>

                { this.state.initialTransferCardRequest.Status === INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING && this.props.respondable ?

                    <>

                        <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                        <Button onClick={ () => this.respond( INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED ) } disabled={ this.state.saving }>{ _( 'Akzeptieren' ) }</Button>

                        <Button onClick={ () => this.respond( INITIAL_TRANSFER_CARD_REQUEST_STATUS_REJECTED ) } disabled={ this.state.saving } variant='outline-danger'>{ _( 'Ablehnen' ) }</Button>

                    </>

                :

                    <>

                        <Button onClick={ this.props.onHide }>{ _( 'Schließen' ) }</Button>

                    </>

                }

            </>

        )

    }

    renderDocuments() {

        return (

            <>

                <Documents
                    disabled=     { ! this.canEdit() || this.state.saving }
                    documents=    { this.state.initialTransferCardRequest.PersonDocuments }
                    documentTypes={ this.state.documentTypes }
                    objectName=   'InitialTransferCardRequest'
                    onChange=     { e => this.setState( { initialTransferCardRequest: { ...this.state.initialTransferCardRequest, PersonDocuments: updateItem( this.state.initialTransferCardRequest.PersonDocuments, e ) } } ) }
                    onDelete=     { e => this.setState( { initialTransferCardRequest: { ...this.state.initialTransferCardRequest, PersonDocuments: deleteItem( this.state.initialTransferCardRequest.PersonDocuments, e ) } } ) }
                    requiredKey=  { olderThan( this.state.initialTransferCardRequest.Birthdate, 18 ) ? 'requiredForInitialTransfercardRequestOver18' : youngerThan( this.state.initialTransferCardRequest.Birthdate, 18 ) ? 'requiredForInitialTransfercardRequestUnder18' : '' }
                    uploadParams= { { personRequestId: this.state.initialTransferCardRequest.Id } }
                />

            </>

        )

    }

    renderFields() {

        return (

            <Form onSubmit={ e => e.preventDefault() } noValidate validated={ this.state.validated } ref={ this.#dom.form }>

                <FieldSet
                    disabled=       { ! this.canEdit() || this.state.saving }
                    fieldDefinition={ this.state.fieldDefinition }
                    onBlur=         { e => this.handleBlur( e ) }
                    onChange=       { ( e, f ) => this.setState( { initialTransferCardRequest: { ...this.state.initialTransferCardRequest, [ e ]: f } } ) }
                    valueOptions=   { this.state.valueOptions }
                    values=         { this.state.initialTransferCardRequest }
                />

            </Form>

        )

    }

    renderRequestBody() {

        return (

            this.state.step === 1 ?

                this.renderFields()

            : this.state.step === 2 ?

                this.renderDocuments()

            :

                ( this.state.saving ?

                    this.getStatusMessage( _( 'Antrag wird gesendet...' ), '', 'spinner', true )

                : this.state.success ?

                    this.getStatusMessage( _( 'Antrag wurde gesendet.' ), 'text-success', 'check' )

                : this.state.error ?

                    this.getStatusMessage( _( 'Antrag konnte nicht gesendet werden.' ), 'text-danger', 'times' )

                :

                    ''

                )

        )

    }

    renderRequestButtons() {

        const missingDocuments = []

        this.state.documentTypes && this.state.documentTypes.forEach( documentType => {

            const otherConfig = parseJSON( documentType.otherConfig, {} )

            if ( olderThan( this.state.initialTransferCardRequest.Birthdate, 18 ) && otherConfig.requiredForInitialTransfercardRequestOver18 && ! this.state.initialTransferCardRequest.PersonDocuments.find( e => e.DocumentType.Id === documentType.id ) ) {

                missingDocuments.push( documentType )

            } else if ( youngerThan( this.state.initialTransferCardRequest.Birthdate, 18 ) && otherConfig.requiredForInitialTransfercardRequestUnder18 && ! this.state.initialTransferCardRequest.PersonDocuments.find( e => e.DocumentType.Id === documentType.id ) ) {

                missingDocuments.push( documentType )

            }

        } )

        return (

            <>

                { this.state.step < 3 && <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button> }

                { this.state.step === 1 ?

                    <Button onClick={ () => this.save( INITIAL_TRANSFER_CARD_REQUEST_STATUS_DRAFT, 2 ) } disabled={ this.state.saving }>{ _( 'Weiter' ) }</Button>

                : this.state.step === 2 ?

                    <>

                        <Button variant='secondary' onClick={ () => this.setState( { step: 1 } ) }>{ _( 'Zurück' ) }</Button>

                        <Button onClick={ () => this.send() } disabled={ missingDocuments.length > 0 }>{ _( 'Antrag senden' ) }</Button>

                    </>

                :

                    <Button onClick={ this.props.onHide } disabled={ this.state.saving }>{ _( 'Schließen' ) }</Button>

                }

            </>

        )

    }

    render() {

        return (

            <Modal show={ this.state.initialTransferCardRequest !== null } onHide={ this.props.onHide } backdrop='static' size='lg'>

                { this.state.initialTransferCardRequest &&

                    <>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Lizenzerstausstellung mit Transferkarte' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            { this.state.isNew ? this.renderRequestBody() : this.renderBody() }

                        </Modal.Body>

                        <Modal.Footer>

                            { this.state.isNew ? this.renderRequestButtons() : this.renderButtons() }

                        </Modal.Footer>

                    </>

                }

            </Modal>

        )

    }

}