import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Placeholder, Row } from 'react-bootstrap'
import ItemOptions from '../elements/ItemOptions'

export default class Item extends React.Component {

    renderName() {

        return (

            this.props.url ?

                <Link to={ this.props.url } onClick={ this.props.onClick }>{ this.props.name }</Link>

            :

                this.props.name

        )

    }

    render() {

        return (

            <Card className='mb-1'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col className='fs-6' { ...( this.props.colProps || {} ) }>

                            { this.props.item ?

                                ( this.props.options ?

                                    <Row className='align-items-center flex-nowrap'>

                                        <Col xs='auto'>

                                            <ItemOptions className='d-inline-block me-3'>

                                                { this.props.options }

                                            </ItemOptions>

                                        </Col>

                                        <Col>

                                            { this.renderName() }

                                        </Col>

                                    </Row>

                                :

                                    this.renderName()

                                )

                             :

                                <Placeholder animation='wave'><Placeholder xs={ this.props.index === 0 ? 9 : this.props.index === 1 ? 4 : 7 } bg='primary' /></Placeholder>

                            }

                        </Col>

                        { this.props.children }

                    </Row>

                    { this.props.footer }

                </Card.Body>

            </Card>

        )

    }

}