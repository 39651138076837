import React from 'react'
import { withRouter } from 'react-router-dom'
import { formatDateTime } from '@morawadigital/skynet-framework'
import { Col, OverlayTrigger, Placeholder, Tooltip } from 'react-bootstrap'
import Item from './Item'
import { getInitialTransferCardRequestStatus, getStatusLabel } from '../../util'
import { INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED, INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING, INITIAL_TRANSFER_CARD_REQUEST_STATUS_REJECTED } from '../../util/constants'

class InitialTransferCardRequest extends React.Component {

    handleClick( e ) {

        e.preventDefault()

        this.props.onClick( this.props.item )

    }

    render() {

        const item   = this.props.item
        const status = item && getInitialTransferCardRequestStatus( item.Status )

        return (

            <Item
                colProps={ { xs: 8, md: 3 }                                               }
                index=   { this.props.index                                               }
                item=    { item                                                           }
                name=    { item && item.personName                                        }
                onClick= { item && this.props.onClick ? e => this.handleClick( e ) : null }
                url=     { item && '/'                                                    }
            >

                <Col xs='4' md='3' className='text-end text-md-start'>

                    { item ?

                        item.Club && <OverlayTrigger overlay={ <Tooltip>{ item.Club.Name }</Tooltip> }><span>{ item.Club.Shortname }</span></OverlayTrigger>

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='6' md='3'>

                    { item ?

                        item.RequestDate && formatDateTime( item.RequestDate)

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='6' md='3' className='text-end'>

                    { item ? (

                        status ? (

                            status.value === INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING ?

                                getStatusLabel( status.label, 'clock', 'info' )

                            : status.value === INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED ?

                                getStatusLabel( status.label, 'check', 'success', item.SolvedBy, item.SolvedDate )

                            : status.value === INITIAL_TRANSFER_CARD_REQUEST_STATUS_REJECTED ?

                                getStatusLabel( status.label, 'times', 'danger', item.SolvedBy, item.SolvedDate )

                            :

                                ''

                        ) : ''

                    ) :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

            </Item>

        )

    }

}

export default withRouter( InitialTransferCardRequest )