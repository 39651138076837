import { connect } from 'react-redux'
import { setUiValue } from '../../store/actions'
import Persons from '../../components/pages/Persons'

const mapStateToProps = state => ( {

    clubFilterPersons: state.ui.clubFilterPersons || [],
    context:           state.ui.context,
    loading:           state.app.loading,
    textFilter:        state.ui.textFilterPersons || '',

} )

const mapDispatchToProps = dispatch => ( {

    onUiValueChange: ( key, value ) => dispatch( setUiValue( key, value ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( Persons )