import React from 'react'
import { withRouter } from 'react-router-dom'
import { translate as _ } from '@morawadigital/skynet-framework'
import { Dropdown } from 'react-bootstrap'
import Item from './Item'

class Federation extends React.Component {

    renderOptions( url ) {

        return (

            <Dropdown.Item as='button' onClick={ () => this.props.history.push( url ) }>{ _( 'Bearbeiten' ) }</Dropdown.Item>

        )

    }

    render() {

        const item = this.props.item
        const url  = item && _( '/verband/' ) + item.Id

        return (

            <Item
                index=   { this.props.index          }
                item=    { item                      }
                name=    { item && item.Name         }
                options= { this.renderOptions( url ) }
                url=     { url                       }
            />

        )

    }

}

export default withRouter( Federation )