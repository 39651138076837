import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_FLEX_DATE, FIELD_TYPE_RANGE } from './constants'

const fields = [

    { group: 'club',   name: 'name',       label: 'Name',         keyWord: 'verein',     param: 'name'                                                             },

    { group: 'person', name: 'passNumber', label: 'Passnummer',   keyWord: 'passnr',     param: 'passNr'                                                           },
    { group: 'person', name: 'lastName',   label: 'Nachname',     keyWord: 'nachname',   param: 'lastName'                                                         },
    { group: 'person', name: 'firstName',  label: 'Vorname',      keyWord: 'vorname',    param: 'firstName'                                                        },
    { group: 'person', name: 'birthdate',  label: 'Geburtsdatum', keyWord: 'geboren',    param: 'birthdate',  type: FIELD_TYPE_FLEX_DATE                           },
    { group: 'person', name: 'yob',        label: 'Jahrgang',     keyWord: 'jahrgang',   param: 'yearFromTo', type: FIELD_TYPE_RANGE                               },
    { group: 'person', name: 'gender',     label: 'Geschlecht',   keyWord: 'geschlecht', param: 'gender',     type: FIELD_TYPE_AUTOCOMPLETE, optionsKey: 'genders' },

]

export const getSearchFields = () => fields