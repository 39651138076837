import { Icon, translate as _, hasPrivilege } from '@morawadigital/skynet-framework'
import { matchPath } from 'react-router-dom'
import { MODULE_ACCOUNTING, MODULE_COMPETITION_MANAGEMENT, MODULE_LICENSING, PRIV_LOAD_CLUBS, PRIV_LOAD_FEDERATIONS, PRIV_LOAD_PERSONS, PRIV_LOAD_PERSON_REQUESTS, PRIV_LOAD_PLAYER_LICENSE_REQUESTS, PRIV_LOAD_TRANSFER_REQUESTS } from './constants'
import Club from '../containers/pages/Club'
import Clubs from '../containers/pages/Clubs'
import Person from '../containers/pages/Person'
import Persons from '../containers/pages/Persons'
import Requests from '../containers/pages/Requests'
import Dashboard from '../containers/pages/Dashboard'
import Federation from '../containers/pages/Federation'
import Federations from '../containers/pages/Federations'

const modules = {}

modules[ MODULE_ACCOUNTING ] = {

    mainNavigationMenuItems: [

    ],

    pageRoutes: [

    ],

}

modules[ MODULE_COMPETITION_MANAGEMENT ] = {

    mainNavigationMenuItems: [

        // { context: 'federation', url: '/competitions', label: <><Icon fw icon='trophy' /><span className='nav-link-text'>{ _( 'Competitions' ) }</span></>, requiredPrivilege: PRIV_LOAD_SEASON_LEAGUES, },

    ],

    pageRoutes: [

        // { context: 'federation', path: '/competitions',    children: <Competitions />, requiredPrivilege: PRIV_LOAD_SEASON_LEAGUES },
        // { context: 'federation', path: '/competition/:id', children: <Competition  />, requiredPrivilege: PRIV_LOAD_SEASON_LEAGUES },

    ],

}

modules[ MODULE_LICENSING ] = {

    mainNavigationMenuItems: [

        { context: 'federation', url: '/dashboard', label: ()      => <><Icon fw icon='dashboard' /><span className='nav-link-text'>{ _( 'Dashboard' )                             }</span></>,                                                                                                                                                        },
        { context: 'federation', url: '/verbaende', label: ()      => <><Icon fw icon='cubes'     /><span className='nav-link-text'>{ _( 'Verbände'  )                             }</span></>, requiredPrivilege: PRIV_LOAD_FEDERATIONS,                                                                                                              },
        { context: 'federation', url: '/vereine',   label: ()      => <><Icon fw icon='flag'      /><span className='nav-link-text'>{ _( 'Vereine'   )                             }</span></>, requiredPrivilege: PRIV_LOAD_CLUBS,                                                                                                                    },
        { context: 'club',       url: '/verein',    label: context => <><Icon fw icon='flag'      /><span className='nav-link-text'>{ context && context.club && context.club.name }</span></>, requiredPrivilege: PRIV_LOAD_CLUBS,                                                                                                                    },
        {                        url: '/personen',  label: ()      => <><Icon fw icon='users'     /><span className='nav-link-text'>{ _( 'Personen'  )                             }</span></>, requiredPrivilege: PRIV_LOAD_PERSONS,                                                                                                                  },
        {                        url: '/antraege',  label: ()      => <><Icon fw icon='id-card'   /><span className='nav-link-text'>{ _( 'Anträge'   )                             }</span></>,                                           anyPrivilege: [ PRIV_LOAD_PLAYER_LICENSE_REQUESTS, PRIV_LOAD_PERSON_REQUESTS, PRIV_LOAD_TRANSFER_REQUESTS ], },

    ],

    pageRoutes: [

        { context: 'federation', path: '/dashboard',   children: <Dashboard   />,                                                                                                                                                        },
        { context: 'federation', path: '/verband/:id', children: <Federation  />, requiredPrivilege: PRIV_LOAD_FEDERATIONS,                                                                                                              },
        { context: 'federation', path: '/verbaende',   children: <Federations />, requiredPrivilege: PRIV_LOAD_FEDERATIONS,                                                                                                              },
        {                        path: '/verein/:id',  children: <Club        />, requiredPrivilege: PRIV_LOAD_CLUBS,                                                                                                                    },
        { context: 'federation', path: '/vereine',     children: <Clubs       />, requiredPrivilege: PRIV_LOAD_CLUBS,                                                                                                                    },
        {                        path: '/person/:id',  children: <Person      />, requiredPrivilege: PRIV_LOAD_PERSONS,                                                                                                                  },
        {                        path: '/personen',    children: <Persons     />, requiredPrivilege: PRIV_LOAD_PERSONS,                                                                                                                  },
        {                        path: '/antraege',    children: <Requests    />,                                           anyPrivilege: [ PRIV_LOAD_PLAYER_LICENSE_REQUESTS, PRIV_LOAD_PERSON_REQUESTS, PRIV_LOAD_TRANSFER_REQUESTS ], },

    ],

}

export const getDefaultModule = () => MODULE_LICENSING

export const getItems = ( key, module, context, user ) => {

    if ( ! ( module in modules ) ) {

        return

    }

    return modules[ module ][ key ].filter( item => {

        if ( item.requiredPrivilege && ! hasPrivilege( user, item.requiredPrivilege ) ) {

            return false

        }

        if ( item.anyPrivilege && ! item.anyPrivilege.find( privilege => hasPrivilege( user, privilege ) ) ) {

            return false

        }

        return ! item.context || ( context && ( ( context.club && item.context === 'club' ) || ( context.federation && item.context === 'federation' ) ) )

    } )

}

export const getModuleByRoute = ( route = window.location.pathname ) => {

    for ( const module in modules ) {

        for ( const pageRoute of modules[ module ].pageRoutes ) {

            if ( matchPath( route, { path: pageRoute.path } ) ) {

                return module

            }

        }

    }

    return null

}