import React from 'react'
import { mdr } from '@morawadigital/skynet-framework'

export default class Report extends React.Component {

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            reportFrameHeight: null,

        }

        this.#dom = {

            reportFrame: React.createRef(),

        }

        this.handleWindowResize = this.handleWindowResize.bind( this )

    }

    componentDidMount() {

        this.calculateReportFrameHeight()

        this.setContent()

        window.addEventListener( 'resize', this.handleWindowResize )

    }

    componentWillUnmount() {

        window.removeEventListener( 'resize', this.handleWindowResize )

    }

    calculateReportFrameHeight() {

        this.setState( { reportFrameHeight: this.#dom.reportFrame.current.offsetWidth * .66 } )

    }

    handleWindowResize() {

        this.calculateReportFrameHeight()

    }

    setContent() {

        const doc = this.#dom.reportFrame.current.contentWindow.document

        doc.open()
        doc.write( mdr.getContent( this.props.report ) )
        doc.close()

    }

    render() {

        return (

            <iframe
                className='report-frame'
                ref={ this.#dom.reportFrame }
                style={ { height: this.state.reportFrameHeight + 'px' } }
                title=' '
            ></iframe>

        )

    }

}