import React from 'react'
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import { ajx, ajxs, translate as _ } from '@morawadigital/skynet-framework'
import PlayerLicenses from '../../containers/elements/PlayerLicenses'
import TransferCards from '../../containers/elements/TransferCards'
import FieldSet from './FieldSet'
import { getFieldDefinition } from '../../fieldDefinition'
import { createOptions, getBooleanValue, getNumberValue, getSelectValue, getSides, getStringValue } from '../../util'
import Floaties from '../controls/Floaties'

export default class Player extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            fieldDefinition:   null,
            hasUnsavedChanges: false,
            saving:            false,
            validated:         false,
            valueOptions:      { sides: getSides() },

        }

        this.checkUnsavedChanges = this.checkUnsavedChanges.bind( this )

    }

    componentDidMount() {

        window.addEventListener( 'beforeunload', this.checkUnsavedChanges )

        this.loadFieldDefinition()

        this.load()

    }

    componentWillUnmount() {

        window.removeEventListener( 'beforeunload', this.checkUnsavedChanges )

    }

    checkUnsavedChanges( e ) {

        if ( this.state.hasUnsavedChanges ) {

            e.preventDefault()

            return e.returnValue = _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' )

        }

    }

    load() {

        ajxs( { toggleLoading: true }, [

            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, clubs:             createOptions( e ) } } ), url: 'api/Club/Get'             },
            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, leagues:           createOptions( e ) } } ), url: 'api/League/Get'           },
            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, playerContingents: createOptions( e ) } } ), url: 'api/PlayerContingent/Get' },
            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, playerStatuses:    createOptions( e ) } } ), url: 'api/PlayerStatus/Get'     },

        ] )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'Player',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    save( e ) {

        e.preventDefault()

        if ( ! e.currentTarget.checkValidity() ) {

            this.setState( { validated: true } )

            return

        }

        this.setState( { saving: true, validated: true }, () => {

            const data = {

                clubId:                         getSelectValue( this.props.player.Club                            ),
                id:                             this.props.player.Id,
                height:                         getNumberValue( this.props.player.Height                          ),
                informationForAction:           getStringValue( this.props.player.InformationForAction            ),
                medicalRecords:                 getStringValue( this.props.player.MedicalRecords                  ),
                needsTransferCard:              getBooleanValue( this.props.player.NeedsTransferCard              ),
                note:                           getStringValue( this.props.player.Note                            ),
                passNr:                         getStringValue( this.props.player.PassNr                          ),
                personId:                       this.props.player.PersonId,
                playerContingentId:             getSelectValue( this.props.player.PlayerContingent                ),
                playerStatusId:                 getSelectValue( this.props.player.PlayerStatus                    ),
                shotCatchHand:                  getSelectValue( this.props.player.ShotCatchHand                   ),
                waiverOfMedicalConfidentiality: getBooleanValue( this.props.player.WaiverOfMedicalConfidentiality ),
                weight:                         getNumberValue( this.props.player.Weight                          ),

            }

            ajx( {

                complete: () => this.setState( { saving:            false } ),
                single:   true,
                success:  e  => this.setState( { hasUnsavedChanges: false }, () => ! this.props.player.Id && this.props.onChange( 'Id', e.Id ) ),
                url:      'api/Player/AddOrEdit',

                data,

            } )

        } )

    }

    render() {

        const formDisabled = this.props.loading || this.state.saving

        return (

            <>

                <Row>

                    <Col xs='12' xxl='6' className='mb-3'>

                        <Card>

                            <Card.Body>

                                <Form onSubmit={ e => this.save( e ) } noValidate validated={ this.state.validated }>

                                    <fieldset disabled={ formDisabled }>

                                        <FieldSet
                                            disabled={ formDisabled }
                                            fieldDefinition={ this.state.fieldDefinition }
                                            onChange={ ( e, f ) => this.setState( { hasUnsavedChanges: true }, () => this.props.onChange( e, f ) ) }
                                            valueOptions={ this.state.valueOptions }
                                            values={ this.props.player }
                                        />

                                    </fieldset>

                                    <Floaties buttons={ [ { icon: 'save', label: _( 'Speichern' ), disabled: formDisabled, type: 'submit' } ] } />

                                </Form>

                            </Card.Body>

                        </Card>

                    </Col>

                    <Col xs='12' xxl='6' className='mb-3'>

                        <Accordion defaultActiveKey='0'>

                            <Accordion.Item eventKey='0'>

                                <Accordion.Header>{ _( 'Lizenzen' ) }</Accordion.Header>

                                <Accordion.Body>

                                    { ! this.props.player.Id ?

                                        <p className='text-muted'>{ this.props.isFemale ? _( 'Bitte speichern Sie die Spielerin zuerst, um Lizenzen hinzuzufügen.' ) : _( 'Bitte speichern Sie den Spieler zuerst, um Lizenzen hinzuzufügen.' ) }</p>

                                    :

                                        <PlayerLicenses
                                            isFemale=                               { this.props.isFemale }
                                            missingDocumentsForPlayerLicense=       { this.props.missingDocumentsForPlayerLicense }
                                            missingDocumentsForPlayerLicenseRequest={ this.props.missingDocumentsForPlayerLicenseRequest }
                                            onAddPlayerLicenseRequest=              { e => this.props.onAddPlayerLicenseRequest( e ) }
                                            onDelete=                               { e => this.props.onDeletePlayerLicense( e ) }
                                            onUpdate=                               { e => this.props.onUpdatePlayerLicense( e ) }
                                            playerId=                               { this.props.player.Id }
                                            playerLicenseRequests=                  { this.props.player.PlayerLicenseRequests }
                                            playerLicenses=                         { this.props.player.PlayerLicenses }
                                            valueOptions=                           { this.state.valueOptions }
                                        />

                                    }

                                </Accordion.Body>

                            </Accordion.Item>

                            <Accordion.Item eventKey='1'>

                                <Accordion.Header>{ _( 'Transferkarten' ) }{ this.props.player.Id ? ' (' + this.props.player.TransferCards.length + ')' : '' }</Accordion.Header>

                                <Accordion.Body>

                                    { ! this.props.player.Id ?

                                        <p className='text-muted'>{ this.props.isFemale ? _( 'Bitte speichern Sie die Spielerin zuerst, um Transferkarten hinzuzufügen.' ) : _( 'Bitte speichern Sie den Spieler zuerst, um Transferkarten hinzuzufügen.' ) }</p>

                                    :

                                        <TransferCards
                                            onDelete=     { e => this.props.onDeleteTransferCard( e ) }
                                            onUpdate=     { e => this.props.onUpdateTransferCard( e ) }
                                            playerId=     { this.props.player.Id }
                                            transferCards={ this.props.player.TransferCards }
                                            valueOptions= { this.state.valueOptions }
                                        />

                                    }

                                </Accordion.Body>

                            </Accordion.Item>

                        </Accordion>

                    </Col>

                </Row>

                <Prompt message={ _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' ) } when={ this.state.hasUnsavedChanges } />

            </>

        )

    }

}