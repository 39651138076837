import { connect } from 'react-redux'
import App from '../components/App'
import { setUiValue } from '../store/actions'
import { convertContexts } from '../util/context'

const mapStateToProps = state => ( {

    context:  state.ui.context,
    contexts: convertContexts( ( state.auth.user && state.auth.user.Persons ) || [] ),
    loading:  state.app.loading,
    module:   state.ui.module,
    user:     state.auth.user,

} )

const mapDispatchToProps = dispatch => ( {

    onSetContext: context => dispatch( setUiValue( 'context', context ) ),
    onSetModule:  module  => dispatch( setUiValue( 'module',  module  ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( App )