import React from 'react'
import { Alert, Col, Dropdown, Placeholder, SplitButton } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { Icon, translate as _, ajx, formatDateTime } from '@morawadigital/skynet-framework'
import Item from './Item'
import { getFullName, getStatusLabel, getTransferRequestStatus } from '../../util'
import { TRANSFER_REQUEST_STATUS_ACCEPTED, TRANSFER_REQUEST_STATUS_CANCELED, TRANSFER_REQUEST_STATUS_PENDING, TRANSFER_REQUEST_STATUS_REJECTED } from '../../util/constants'
import ItemOverlay from '../elements/ItemOverlay'
import ForwardRequest from '../modals/ForwardRequest'
import { isFederation } from '../../util/context'

class TransferRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            forwardRequestModalOpen: false,
            overlayText:             null,

        }

    }

    forwardRequest( e ) {

        this.setState( { forwardRequestModalOpen: false, overlayText: <><Icon icon='spinner' spin /> { _( 'Wird weitergeleitet...' ) }</> }, () =>

            ajx( {

                complete: () => setTimeout( () => this.setState( { overlayText: null } ), 2000 ),
                data:     { id: this.props.item.Id, fedId: e.federationId, comment: e.comment },
                single:   true,
                success:  e => this.setState( { overlayText: <><Icon icon='check' /> { _( 'Weitergeleitet' ) }</> }, () => setTimeout( () => this.props.onDelete( e ), 2000 ) ),
                url:      'api/TransferRequest/Forward',

            } )

        )

    }

    respond( status, overlayText, overlayTextOnSuccess, overlayIconOnSuccess ) {

        this.setState( { overlayText: <><Icon icon='spinner' spin /> { overlayText }</> }, () =>

            ajx( {

                complete: () => setTimeout( () => this.setState( { overlayText: null } ), 2000 ),
                data:     { id: this.props.item.Id, status },
                single:   true,
                success:  e => this.setState( { overlayText: <><Icon icon={ overlayIconOnSuccess } /> { overlayTextOnSuccess }</> }, () => setTimeout( () => this.props.onUpdate( e ), 2000 ) ),
                url:      'api/TransferRequest/Respond',

            } )

        )

    }

    renderFooter( item ) {

        return (

            <>

                { item && <>

                    { ( item.Comment || item.RequestBy || item.RequestDate ) &&

                        <Alert variant='info' className='mt-2 mb-0 p-1'>

                            { item.RequestDate && <span className='me-1'>{ formatDateTime( item.RequestDate ) }</span> }

                            { item.RequestBy && <>{ _( 'von' ) } <span className='fw-700'>{ getFullName( item.RequestBy ) }</span></> }

                            { item.RequestBy && item.Comment && ': ' }

                            { item.Comment && <span className='fst-italic'>{ item.Comment }</span> }

                        </Alert>

                    }

                    { isFederation( this.props.context ) && item.ForwardComment &&

                        <Alert variant='warning' className='mt-2 mb-0 p-1'>

                            { _( 'Antrag wurde weitergeleitet. Kommentar:' ) } <span className='fst-italic'>{ item.ForwardComment }</span>

                        </Alert>

                    }

                </>}

                { this.state.overlayText && <ItemOverlay>{ this.state.overlayText }</ItemOverlay> }

            </>

        )

    }

    render() {

        const item   = this.props.item
        const status = item && getTransferRequestStatus( item.Status )

        return (

            <>

                <Item
                    colProps={ { xs: 12, xl: 3 }                                                                    }
                    footer=  { this.renderFooter( item )                                                            }
                    index=   { this.props.index                                                                     }
                    item=    { item                                                                                 }
                    name=    { item && item.personName                                                              }
                    url=     { item && item.Player && item.Player.Person && _( '/person/' ) + item.Player.Person.Id }
                >

                    <Col xs='12' sm='6' xl='4'>

                        { item ?

                            <>{ item.FromClub && item.FromClub.Name } <Icon icon='arrow-right' /> { item.ToClub && item.ToClub.Name }</>

                        :

                            <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                        }

                    </Col>

                    <Col xs='12' sm='6' xl='3'>

                        { item ?

                            item.TargetLeague && item.TargetLeague.Name

                        :

                            <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                        }

                    </Col>

                    <Col xs='12' xl='2' className='my-2 text-center text-xl-end'>

                        { item ? (

                            status ? (

                                status.value === TRANSFER_REQUEST_STATUS_PENDING ?

                                    ( this.props.respondable ?

                                        <SplitButton
                                            align='end'
                                            onClick={ () => this.respond( TRANSFER_REQUEST_STATUS_ACCEPTED, _( 'Wird akzeptiert...' ), _( 'Akzeptiert' ), 'check' ) }
                                            title={ _( 'Akzeptieren' ) }
                                        >

                                            <Dropdown.Item as='button' onClick={ () => this.respond( TRANSFER_REQUEST_STATUS_REJECTED, _( 'Wird abgelehnt...' ), _( 'Abgelehnt' ), 'times' ) }>{ _( 'Ablehnen'   ) }</Dropdown.Item>
                                            <Dropdown.Item as='button' onClick={ () => this.respond( TRANSFER_REQUEST_STATUS_CANCELED, _( 'Wird storniert...' ), _( 'Storniert' ), 'ban'   ) }>{ _( 'Stornieren' ) }</Dropdown.Item>
                                            <Dropdown.Item as='button' onClick={ () => this.setState( { forwardRequestModalOpen: true } ) }>{ _( 'Weiterleiten' ) }</Dropdown.Item>

                                        </SplitButton>

                                    :

                                        getStatusLabel( status.label, 'clock', 'info' )

                                    )

                                : status.value === TRANSFER_REQUEST_STATUS_ACCEPTED ?

                                    getStatusLabel( status.label, 'check', 'success', item.RequestSolvedBy, item.RequestSolvedDate )

                                : status.value === TRANSFER_REQUEST_STATUS_REJECTED ?

                                    getStatusLabel( status.label, 'times', 'danger', item.RequestSolvedBy, item.RequestSolvedDate )

                                : status.value === TRANSFER_REQUEST_STATUS_CANCELED ?

                                    getStatusLabel( status.label, 'ban', 'warning', item.RequestSolvedBy, item.RequestSolvedDate )

                                :

                                    ''

                            ) : ''

                        ) :

                            <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                        }

                    </Col>

                </Item>

                <ForwardRequest
                    federations={ this.props.federations                                    }
                    show=       { this.state.forwardRequestModalOpen                        }
                    onFoward=   { e => this.forwardRequest( e )                             }
                    onHide=     { () => this.setState( { forwardRequestModalOpen: false } ) }
                />

            </>

        )

    }

}

export default withRouter( TransferRequest )