import { Link } from 'react-router-dom'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import ItemsPage from './ItemsPage'
import Federation from '../items/Federation'

export default class Federations extends ItemsPage {

    constructor( props ) {

        super( props )

        this.itemComponent = Federation
        this.pageIcon      = 'cubes'
        this.pageTitle     = _( 'Verbände' )

    }

    getDataUrl() {

        return 'api/Federation/Get'

    }

    renderSubheaderBlock() {

        return (

            <Link to={ _( '/verband/neu' ) } className='btn btn-primary'><Icon icon='plus' /> { _( 'Neu' ) }</Link>

        )

    }

}