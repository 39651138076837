import React from 'react'
import { ErrorBoundary, Login, MenuItem, Skynet, createDate, hasPrivilege, reloadPage, translate as _ } from '@morawadigital/skynet-framework'
import { Route } from 'react-router-dom'
import packageJson from '../../../package.json'
import { isDevelopment } from '../util'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import { PRIV_LOAD_AGEGROUPS, PRIV_LOAD_NATIONS } from '../util/constants'
import { getCustomer } from '../util/customer'
import SearchPage from './pages/Search'
import Search from '../containers/controls/Search'
import ModuleSelector from '../containers/controls/ModuleSelector'
import AgeGroups from '../containers/pages/AgeGroups'
import AgeGroup from '../containers/pages/AgeGroup'
import Nations from '../containers/pages/Nations'
import Nation from '../containers/pages/Nation'
import Context from '../containers/controls/Context'
import { getDefaultModule, getItems, getModuleByRoute } from '../util/modules'

export default class App extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            appUpdateAvailable: false,
            updatingApp:        false,

        }

    }

    componentDidMount() {

        setTimeout( () => {

            if ( window.appUpdateAvailable ) {

                this.setState( { appUpdateAvailable: true } )

                setTimeout( () => this.setState( { appUpdateAvailable: false } ), 1000 * 60 )

            }

        }, 1000 * 5 )

    }

    componentDidUpdate() {

        if ( this.props.user ) {

            if ( ! this.props.context && this.props.contexts.length ) {

                this.props.onSetContext( this.props.contexts[ 0 ] )

            } else if ( this.props.context && ! this.props.contexts.find( e => e.value === this.props.context.value ) ) {

                this.props.onSetContext( null )

            }

            if ( ! this.props.module ) {

                this.props.onSetModule( getDefaultModule() )

            } else if ( window.location.pathname !== '/' ) {

                const module = getModuleByRoute()

                if ( module && this.props.module !== module ) {

                    this.props.onSetModule( module )

                }

            }

        }

    }

    getBaseDataUrl( customer ) {

        if ( process.env.REACT_APP_BASE_DATA_URL ) {

            return process.env.REACT_APP_BASE_DATA_URL + ( process.env.REACT_APP_BASE_DATA_PORT ? ':' + process.env.REACT_APP_BASE_DATA_PORT + '/' : '' )

        }

        if ( isDevelopment() ) {

            return customer.devBaseDataUrl

        }

        return customer.baseDataUrl[ window.location.hostname ]

    }

    getMainNavigationMenuItems() {

        const mainNavigationMenuItems = getItems( 'mainNavigationMenuItems', this.props.module, this.props.context, this.props.user ) || []

        if ( mainNavigationMenuItems.length ) {

            mainNavigationMenuItems[ 0 ].url = '/'

        }



        return mainNavigationMenuItems && mainNavigationMenuItems.map( e => ( { ...e, url: _( e.url ) } ) )

    }

    getPageRoutes() {

        const pageRoutes = ( getItems( 'pageRoutes', this.props.module, this.props.context, this.props.user ) || [] ).map( e => ( { ...e, path: _( e.path ) } ) )

        pageRoutes.push( { path: [ _( '/suche/:query' ), _( '/suche' ) ], children: <SearchPage /> } )

        if ( hasPrivilege( this.props.user, PRIV_LOAD_AGEGROUPS ) ) {

            pageRoutes.push( { path: _( '/altersklasse/:id' ), children: <AgeGroup  /> } )
            pageRoutes.push( { path: _( '/altersklassen'    ), children: <AgeGroups /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_NATIONS ) ) {

            pageRoutes.push( { path: _( '/nation/:id' ), children: <Nation  /> } )
            pageRoutes.push( { path: _( '/nationen'   ), children: <Nations /> } )

        }

        pageRoutes.length && pageRoutes.push( { ...pageRoutes[ 0 ], path: '/' } )

        return pageRoutes

    }

    hideAppUpdateNotification() {

        this.setState( { appUpdateAvailable: false } )

    }

    updateApp() {

        this.setState( { updatingApp: true }, () => {

            caches
                .keys()
                .then( keys => keys.forEach( c => caches.delete( c ) ) )
                .then( () => setTimeout( reloadPage, 1000 * 3 ) )

        } )

    }

    render() {

        const customer     = getCustomer()
        const logoImageUrl = customer.logoImageUrl
        const logoText     = 'myFederation'

        const conf ={

            firebaseConfig:              {

                apiKey:            'AIzaSyDSQoHoe0xzOF_MC-ildFewcc3z2GZzDGU',
                authDomain:        'morawa-skynet-push.firebaseapp.com',
                projectId:         'morawa-skynet-push',
                storageBucket:     'morawa-skynet-push.appspot.com',
                messagingSenderId: '721850682646',
                appId:             '1:721850682646:web:719b96d6ce195b36f9d437',
                measurementId:     'G-J9SFWPY19K',

            },
            baseDataUrl:             this.getBaseDataUrl( customer ),
            footerLeft:              createDate().getFullYear() + ' © Data in Motion',
            footerRight:             'myFederation v' + packageJson.version,
            logoImageUrl:            customer.logoImageUrl,
            logoText:                logoText,
            mainNavigationMenuItems: this.getMainNavigationMenuItems().map( ( e, i ) => <MenuItem url={ e.url } key={ i }>{ typeof e.label === 'function' ? e.label( this.props.context ) : e.label }</MenuItem> ),
            pageRoutes:              this.getPageRoutes().map( ( e, i ) => <Route path={ e.path } key={ i } children={ e.children } /> ),
            portalHeaderCenter:      <><Context /><Search /></>,
            portalHeaderRight:       <><ModuleSelector /></>,
            rootComponent:           <Login logoImageUrl={ logoImageUrl } logoText={ logoText } />,

        }

        return (

            <ErrorBoundary>

                <Skynet { ...conf } />

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast show={ this.state.appUpdateAvailable } onClose={ () => this.hideAppUpdateNotification() }>

                        <Toast.Header>

                            <div className='flex-grow-1'>{ _( 'Update verfügbar' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Eine neue App-Version ist verfügbar!' ) }</p>

                            <Button onClick={ () => this.updateApp() } disabled={ this.state.updatingApp }>{ this.state.updatingApp ? _( 'Installiere...' ) : _( 'Jetzt installieren' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </ErrorBoundary>

        )

    }

}