import React from 'react'
import sprintf from 'sprintf-js'
import { Icon, translate as _, ajx, ajxs, formatDate } from '@morawadigital/skynet-framework'
import { Button, Card, Col, Collapse, Form, Modal, Row } from 'react-bootstrap'
import { createOptions, getFullName, getSelectValue } from '../../util'
import Field from '../controls/Field'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_TEXTAREA } from '../../util/constants'
import { isClub, isFederation } from '../../util/context'

export default class TransferRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = this.getDefaultState()

    }

    componentDidUpdate() {

        this.props.show !== this.state.show && this.setState( {

            ...this.getDefaultState(),

            valueOptions: this.state.valueOptions,
            show:         this.props.show,

        } )

    }

    componentDidMount() {

        const items = [ {

            data:    { fedId: isFederation( this.props.context ) ? this.props.context.federation.id : isClub( this.props.context ) ? this.props.context.club.federationId : '' },
            options: { method: 'GET' },
            success: e => this.setState( { valueOptions: { ...this.state.valueOptions, leagues: createOptions( e ) } } ),
            url:     'api/League/Get',

        } ]

        if ( this.manualClubSelection() ) {

            items.push( {

                options: { method: 'GET' },
                success: e => this.setState( { valueOptions: { ...this.state.valueOptions, clubs: createOptions( e ) } } ),
                url:     'api/Club/Get',

            } )

        }

        ajxs( {}, items )

    }

    getDefaultState() {

        return {

            comment:         '',
            complete:        false,
            error:           false,
            league:          null,
            loadingPlayers:  false,
            playerDetailsId: null,
            players:         [],
            saving:          false,
            search:          { club: null, lastName: '', firstName: '', passNumber: '' },
            selectedPlayer:  null,
            show:            false,
            success:         false,
            transferRequest: {},
            valueOptions:    {},

        }

    }

    getStatusMessage( text, className, icon, spin ) {

        const iconProps = { icon }

        if ( spin ) {

            iconProps.spin = true

        }

        return (

            <div className={ 'text-center ' + className }>

                <div className='fs-1 my-3'>

                    <Icon { ...iconProps } />

                </div>

                <p className='lead'>{ text }</p>

            </div>

        )

    }

    loadPlayers( e ) {

        e.preventDefault()

        if ( ! this.searchFormSubmittable() ) {

            return

        }

        this.setState( { loadingPlayers: true }, () => ajx( {

            complete: () => this.setState( { loadingPlayers: false } ),
            options:  { method: 'GET' },
            success:  players => this.setState( { players } ),
            url:      'api/TransferRequest/GetPlayer',

            data:     {

                clubId:              this.manualClubSelection() ? getSelectValue( this.state.search.club ) : ( isClub( this.props.context ) ? this.props.context.club.id : '' ),
                firstName:           this.state.search.firstName,
                lastName:            this.state.search.lastName,
                playerLicenseTypeId: this.props.playerLicenseType ? this.props.playerLicenseType.Id : '',
                passNr:              this.state.search.passNumber,
                transferable:        ! this.props.playerLicenseType,

            },

        } ) )

    }

    manualClubSelection() {

        return isFederation( this.props.context )

    }

    save() {

        this.setState( { saving: true }, () => ajx( {

            complete: () => this.setState( { saving: false, complete: true } ),
            error:    () => this.setState( { error: true } ),
            single:   true,
            success:  e => this.setState( { success: true }, () => this.props.onUpdate( e ) ),
            url:      'api/TransferRequest/Create',

            data: {

                comment:             this.state.comment,
                playerId:            this.state.selectedPlayer.Id,
                playerLicenseTypeId: this.props.playerLicenseType ? this.props.playerLicenseType.Id : '',
                targetLeagueId:      getSelectValue( this.state.league ),
                toClubId:            this.manualClubSelection() ? getSelectValue( this.state.search.club ) : ( isClub( this.props.context ) ? this.props.context.club.id : '' ),

            }

        } ) )

    }

    searchFormSubmittable() {

        return ( ( ! this.manualClubSelection() || this.state.search.club ) && ( this.state.search.lastName || this.state.search.firstName || this.state.search.passNumber ) )

    }

    renderPlayer( player, i ) {

        let canBeSelected = true
        let details       = []
        let informations  = []

        const detailsOpen    = this.state.playerDetailsId === player.Id
        const addInformation = e => informations.push( e )
        const addDetail      = e => {

            canBeSelected = false

            details.push( e )

        }

        if ( ! this.props.playerLicenseType ) {

            if ( player.notTransferableLicenses && player.notTransferableLicenses.length ) {

                player.notTransferableLicenses.forEach( e =>

                    addDetail(

                        sprintf.sprintf( _( 'Nicht transferierbare Lizenz (%s) bei %s vorhanden.' ), e.PlayerLicenseType ? e.PlayerLicenseType.Name : '???', e.Club ? e.Club.Name : '???' )

                    )

                )

            }

        }

        if ( player.Reasons ) {

            if ( player.Reasons.hasMaximumAmountOfConcurrentLicenses    ) { addDetail( _( 'Maximale Anzahl an aktiven Lizenzen erreicht.'              ) ) }
            if ( player.Reasons.hasMaximumAmountOfSequentialRepetitions ) { addDetail( _( 'Maximale Anzahl an aufeinanderfolgenden Lizenzen erreicht.' ) ) }
            if ( player.Reasons.hasNoValidRequestableLicense            ) { addDetail( _( 'Keine gültige erforderliche Lizenz vorhanden.'              ) ) }
            if ( player.Reasons.hasOpenLicenseRequest                   ) { addDetail( _( 'Offener Lizenzantrag vorhanden.'                            ) ) }
            if ( player.Reasons.hasOpenTransferRequest                  ) { addDetail( _( 'Offener Vereinswechsel-/Lizenzantrag vorhanden.'            ) ) }
            if ( player.Reasons.hasValidLoanAgreement                   ) { addDetail( _( 'Aktiver Leihvertrag vorhanden.'                             ) ) }
            if ( player.Reasons.hasNoActiveRequiredLicense              ) { addDetail( _( 'Keine aktive gültige Lizenz vorhanden.'                     ) ) }

        }

        if ( player.Informations ) {

            if ( player.Informations.OpenSpecialAuthorityRequests ) {

                player.Informations.OpenSpecialAuthorityRequests.forEach( e => addInformation( sprintf( _( 'Offener Sonderberechtigungsantrag vorhanden (%s).' ) ), e.Name ) )

            }

        }

        return (

            <Card
                className={ 'mb-1 ' + ( canBeSelected ? 'selectable' : 'opacity-50' ) }
                key={ i }
                onClick={ canBeSelected ? () => this.setState( { selectedPlayer: player } ) : null }
            >

                <Card.Body className='p-2'>

                    <Row className='gx-0'>

                        <Col xs='3' lg='2'>

                            { player.PassNr }

                        </Col>

                        <Col xs='9' lg='3'>

                            { getFullName( player.Person, true ) }

                        </Col>

                        <Col xs='3' lg='2'>

                            { player.Person && player.Person.Birthdate ? formatDate( player.Person.Birthdate ) : '' }

                        </Col>

                        <Col xs='9' lg='3'>

                            { player.CurrentClub && player.CurrentClub.Name }

                        </Col>

                        <Col xs='11' lg='2'>

                            { player.Release ? <Icon icon='check' className='text-succes' /> : <Icon icon='times' className='text-danger' /> } { _( 'Passfreigabe' ) }

                        </Col>

                        <Col xs='1' lg='12' className='text-end'>

                            { ( details.length > 0 || informations.length > 0 ) && <Icon icon='chevron-down' button fw className={ 'card-details-toggle' + ( detailsOpen ? ' open' : '' ) } onClick={ () => this.setState( { playerDetailsId: detailsOpen ? null : player.Id } ) } /> }

                        </Col>

                    </Row>

                    <Collapse in={ detailsOpen }>

                        <div>

                            <ul className='my-2'>

                                { details.map(      ( e, i ) => <li key={ i }>{ e }</li> ) }
                                { informations.map( ( e, i ) => <li key={ i }>{ e }</li> ) }

                            </ul>

                        </div>

                    </Collapse>

                </Card.Body>

            </Card>

        )

    }

    renderRequestForm() {

        return (

            <>

                <Field
                    field={ { object: 'transfer-request', property: 'league', __label: _( 'Liga' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'leagues' } }
                    onChange={ ( _, league ) => this.setState( { league } ) }
                    value={ this.state.league }
                    valueOptions={ this.state.valueOptions }
                />

                <Field
                    field={ { object: 'transfer-request', property: 'comment', __label: _( 'Kommentar' ), __type: FIELD_TYPE_TEXTAREA } }
                    onChange={ ( _, comment ) => this.setState( { comment } ) }
                    value={ this.state.comment }
                />

            </>

        )

    }

    renderSearchForm() {

        return (

            <>

                <Form onSubmit={ e => this.loadPlayers( e ) } className='mb-3'>

                    <fieldset disabled={ this.state.loadingPlayers }>

                        { this.manualClubSelection() &&

                            <>

                                <Field
                                    disabled={ this.state.loadingPlayers }
                                    field={ { object: 'transfer-request-player-search', property: 'club', __label: _( '1. Zielverein wählen' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'clubs' } }
                                    onChange={ ( _, club ) => this.setState( { search: { ...this.state.search, club } } ) }
                                    value={ this.state.search.club }
                                    valueOptions={ this.state.valueOptions }
                                />

                                <p className='border-top fw-500 mt-3 pt-3'>{ _( '2. Spieler:in suchen:' ) }</p>

                            </>

                        }

                        <Row className='g-1'>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Nachname'   ) } value={ this.state.search.lastName   } onChange={ e => this.setState( { search: { ...this.state.search, lastName:   e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Vorname'    ) } value={ this.state.search.firstName  } onChange={ e => this.setState( { search: { ...this.state.search, firstName:  e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Passnummer' ) } value={ this.state.search.passNumber } onChange={ e => this.setState( { search: { ...this.state.search, passNumber: e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3' className='d-grid'><Button type='submit' disabled={ ! this.searchFormSubmittable() }>{ _( 'Suchen' ) }</Button></Col>

                        </Row>

                    </fieldset>

                </Form>

                { this.state.players.map( ( e, i ) => this.renderPlayer( e, i ) ) }

            </>

        )

    }

    render() {

        return (

            <Modal show={ this.state.show } onHide={ this.props.onHide } backdrop='static' size='lg'>

                <Modal.Header closeButton>

                    <Modal.Title>{ this.props.playerLicenseType ? this.props.playerLicenseType.Name : _( 'Vereinswechsel' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    { this.state.selectedPlayer ?

                        ( this.state.saving ?

                            this.getStatusMessage( isFederation( this.props.context ) ? _( 'Vereinswechsel wird durchgeführt...' ) : _( 'Antrag wird gesendet...' ), '', 'spinner', true )

                        : this.state.success ?

                            this.getStatusMessage( isFederation( this.props.context ) ? _( 'Vereinswechsel wurde durchgeführt.' ) : _( 'Antrag wurde gesendet.' ), 'text-success', 'check' )

                        : this.state.error ?

                            this.getStatusMessage( isFederation( this.props.context ) ? _( 'Vereinswechsel konnte nicht durchgeführt werden.' ) : _( 'Antrag konnte nicht gesendet werden.' ), 'text-danger', 'times' )

                        :

                            this.renderRequestForm()

                        )

                    :

                        this.renderSearchForm()

                    }

                </Modal.Body>

                <Modal.Footer>

                    { ! this.state.saving && ! this.state.complete && <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button> }

                    { this.state.selectedPlayer && ! this.state.saving && ! this.state.complete && <Button onClick={ () => this.save() }>{ isFederation( this.props.context ) ? _( 'Vereinswechsel durchführen' ) : _( 'Antrag senden' ) }</Button> }

                    { this.state.selectedPlayer && this.state.complete && <Button onClick={ this.props.onHide } disabled={ this.state.saving }>{ _( 'Schließen' ) }</Button> }

                </Modal.Footer>

            </Modal>

        )

    }

}