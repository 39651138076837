import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_DATE, FIELD_TYPE_TEXTAREA, FIELD_TYPE_TOGGLE } from '../util/constants'

export const PlayerLicense = {

    'Club':                         { __label: _( 'Verein'                          ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'clubs'                 },
    'DevelopmentLicense':           { __label: _( 'Development License'             ), __type: FIELD_TYPE_TOGGLE                                              },
    'DocumentArrival':              { __label: _( 'Document Arrival'                ), __type: FIELD_TYPE_DATE                                                },
    'FromDate':                     { __label: _( 'Von'                             ), __type: FIELD_TYPE_DATE                                                },
    'LicenseExtensionSecondLeague': { __label: _( 'License Extension Second League' ), __type: FIELD_TYPE_TOGGLE                                              },
    'LicensePrintDate':             { __label: _( 'License Print Date'              ), __type: FIELD_TYPE_DATE                                                },
    'LicenseReturnDate':            { __label: _( 'License Return Date'             ), __type: FIELD_TYPE_DATE                                                },
    'Note':                         { __label: _( 'Notiz'                           ), __type: FIELD_TYPE_TEXTAREA                                            },
    'PlayerLicenseType':            { __label: _( 'Player License Type'             ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'playerLicenseTypes'    },
    'PlayoffClub':                  { __label: _( 'Playoff Verein'                  ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'clubs'                 },
    'Release':                      { __label: _( 'Already Calculated'              ), __type: FIELD_TYPE_TOGGLE                                              },
    'RestrictedLeague':             { __label: _( 'Restricted League'               ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'leagues'               },
    'Status':                       { __label: _( 'Status'                          ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'playerLicenseStatuses' },
    'ToDate':                       { __label: _( 'Bis'                             ), __type: FIELD_TYPE_DATE                                                },

}