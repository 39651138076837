import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_ADDRESS, FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_DATE, FIELD_TYPE_MULTI_TEXT, FIELD_TYPE_TEXTFIELD } from '../util/constants'

export const Person = {

    'Address':      { __label: _( 'Adresse'       ), __type: FIELD_TYPE_ADDRESS                               },
    'Birthdate':    { __label: _( 'Geburtstag'    ), __type: FIELD_TYPE_DATE                                  },
    'BirthName':    { __label: _( 'Mädchenname'   ), __type: FIELD_TYPE_TEXTFIELD                             },
    'Birthplace':   { __label: _( 'Geburtsort'    ), __type: FIELD_TYPE_TEXTFIELD                             },
    'FirstName':    { __label: _( 'Vorname'       ), __type: FIELD_TYPE_TEXTFIELD                             },
    'Gender':       { __label: _( 'Geschlecht'    ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'genders' },
    'Image':        { __label: _( 'Bild'          ), __type: FIELD_TYPE_TEXTFIELD                             },
    'LastName':     { __label: _( 'Nachname'      ), __type: FIELD_TYPE_TEXTFIELD                             },
    'Nation':       { __label: _( 'Nation'        ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'nations' },
    'PhoneNumbers': { __label: _( 'Telefonnummer' ), __type: FIELD_TYPE_MULTI_TEXT                            },
    'SecondNation': { __label: _( '2. Nation'     ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'nations' },

}