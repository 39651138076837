import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_NUMBER, FIELD_TYPE_TEXTFIELD } from '../util/constants'

export const AgeGroup = {

    'FromAge':        { __label: _( 'Mindestalter'               ), __type: FIELD_TYPE_NUMBER                                  },
    'Gender':         { __label: _( 'Geschlecht'                 ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'genders'   },
    'Name':           { __label: _( 'Name'                       ), __type: FIELD_TYPE_TEXTFIELD                               },
    'ParentAgeGroup': { __label: _( 'Übergeordnete Altersklasse' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'ageGroups' },
    'PassExpression': { __label: _( 'Pass Expression'            ), __type: FIELD_TYPE_TEXTFIELD                               },
    'Shortname':      { __label: _( 'Kurzbezeichnung'            ), __type: FIELD_TYPE_TEXTFIELD                               },
    'ToAge':          { __label: _( 'Höchstalter'                ), __type: FIELD_TYPE_NUMBER                                  },

}